
@mixin app-container($isNarrow: false) {
	@include app-toggleContainer(true, $isNarrow);
}

@mixin app-container-wide() {
	@include app-toggleContainer(true, false, true);
}

@mixin app-newcontainer() {
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	padding-left: $newContainerPaddingSmall;
	padding-right: $newContainerPaddingSmall;
	max-width: none;
	width: 100%;

	//>=768px
	@include media($mediumNewLayout) {
		padding-left: $newContainerPaddingMedium;
		padding-right: $newContainerPaddingMedium;
		max-width: $newContainerMaxWidth;
	}
	//>=1280px
	@include media($largeNewLayout) {
		padding-left: $newContainerPaddingLarge;
		padding-right: $newContainerPaddingLarge;
	}
}
