.newLargeList {
	&__category {
		@include font(newCategory);
		margin: {
			bottom: $vwS10;
			top: 0;
		}
		padding-right: 0;
		text-align: left;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin: {
				bottom: 0;
				top: $vwM20;
			}
			padding-right: $categoryMediumPadding;
			text-align: right;
			width: staticColumnSize(4, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $base4;
			padding-right: $vwL20;
			width: staticColumnSize(3, 11, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding-right: $base2;
		}
	}

	&__content {
		@include staticColumns();
		flex-direction: column;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			flex-direction: row;
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(11, 12, $newGutterLarge);
		}
	}

	&__contentWrapper {

		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(8, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(8, 11, $newGutterLarge);
		}
	}

	&__listItem {
		border-top: {
			style: solid;
			width: 1px;
		}
		padding: {
			bottom: $vwS15;
			top: $vwS10;
		}

		//>=768px
		@include media($mediumNewLayout) {
			padding: {
				bottom: $vwM15;
				top: $vwM10;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				bottom: $vwL25;
				top: $vwL20;
			}
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding: {
				bottom: $base2 + $baseHalf;
				top: $base2;
			}
		}

		&:last-child {
			border-bottom: {
				style: solid;
				width: 1px;
			}

			//>=1680px
			@include media($xLargeNewLayout) {
				padding-bottom: $base3;
			}
		}
	}
}
