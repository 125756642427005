.newImage {
	&__caption {
		@include font(newAltTextEditor);
		margin-top: $vwS05;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM05;
		}
		//>=1280
		@include media($largeNewLayout) {
			margin-top: $vwL05;
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			margin-top: $baseHalf;
		}

		& p {
			& a {
				color: $colorHighlight;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.newImage--extraLarge & {
			//>=768px
			@include media($mediumNewLayout) {
				width: staticColumnSize(7, 12, $newGutterSmall);
			}

			//>=1280
			@include media($largeNewLayout) {
				width: staticColumnSize(8, 10, $newGutterLarge);
			}

			//>=1440px
			@include media($xLarge2vNewLayout) {
				width: staticColumnSize(6, 10, $newGutterLarge);
			}
		}

		.newImage--large & {
			//>=768px
			@include media($mediumNewLayout) {
				width: staticColumnSize(7, 9, $newGutterSmall);
			}

			//>=1280
			@include media($largeNewLayout) {
				width: staticColumnSize(6, 8, $newGutterLarge);
			}
		}

		.newImage--medium & {
			//>=1280
			@include media($largeNewLayout) {
				width: staticColumnSize(6, 7, $newGutterLarge);
			}

			//>=1440px
			@include media($xLarge2vNewLayout) {
				width: 100%;
			}
		}

		> * {
			display: inline-block;
		}
	}

	&__content {
		@include staticColumns();
		flex-direction: column;
		margin-left: 0;
		width: 100%;

		//>=1280
		@include media($largeNewLayout) {
			margin-left: staticColumnPush(3, 12, $newGutterLarge);
		}

		.newImage--extraLarge & {
			//>=1280
			@include media($largeNewLayout) {
				margin-left: staticColumnPush(1, 12, $newGutterLarge);
				width: staticColumnSize(10, 12, $newGutterLarge);
			}
		}

		.newImage--large & {
			//>=768px
			@include media($mediumNewLayout) {
				margin-left: staticColumnPush(2, 12, $newGutterSmall);
				width: staticColumnSize(9, 12, $newGutterSmall);
			}
			//>=1280
			@include media($largeNewLayout) {
				margin-left: staticColumnPush(3, 12, $newGutterLarge);
				width: staticColumnSize(8, 12, $newGutterLarge);
			}
		}

		.newImage--medium & {
			//>=768px
			@include media($mediumNewLayout) {
				margin-left: staticColumnPush(4, 12, $newGutterSmall);
				width: staticColumnSize(7, 12, $newGutterSmall);
			}
			//>=1280
			@include media($largeNewLayout) {
				margin-left: staticColumnPush(3, 12, $newGutterLarge);
				width: staticColumnSize(7, 12, $newGutterLarge);
			}

			//>=1440px
			@include media($xLarge2vNewLayout) {
				width: staticColumnSize(6, 12, $newGutterLarge);
			}
		}

		.newImage--small & {
			//>=768px
			@include media($mediumNewLayout) {
				margin-left: staticColumnPush(4, 12, $newGutterSmall);
				width: staticColumnSize(4, 12, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				margin-left: staticColumnPush(3, 12, $newGutterLarge);
				width: staticColumnSize(4, 12, $newGutterLarge);
			}
		}
	}

	&__image {
		width: 100%;
	}

	&__img {
		@include objectFit(cover);
		height: 100%;
		max-width: 100%;
	}

	&__link {
		@include new-caption-link();
		display: inline;

		&::before {
			color: $colorBase;
			content: '|';
			display: inline-block;
			height: 100%;
			margin: {
				left: 0;
				right: $newLinkMarginRight;
			}
			width: 1px;

			.block--darkColorVersion &,
			.block--vividColorVersion & {
				color: $colorBgBase;
			}
		}
	}
}
