.newMenuItem {
	position: relative;

	&--accordion {
		@include ease(height, $durationBase, $easeBase);
		border-top: solid 1px $colorSeparatoraAccordion;
		overflow: hidden;
		padding-bottom: $vwS15;

		&:last-child {
			border-bottom: solid 1px $colorSeparatoraAccordion;
		}
	}

	.newMainMenu__desktopSub &,
	.newMainMenu__mainNav--desktop & {
		opacity: 0;
	}

	@for $i from 0 through 20 {
		.subMenu__content.js-active &:nth-child(#{$i}) {
			.newMainMenu__desktopSub & {
				@include ease(opacity, $durationBase, $easeBase, $durationDelayBetweenElements * $i);
				opacity: 1;
			}
		}
	}

	@for $i from 0 through 20 {
		.newMainNavs.js-secondActiveStep .newMainMenu__desktopMain &:nth-child(#{$i}) {
			@include ease(opacity, $durationBase, $easeBase, $durationDelayBetweenElements * $i);
			opacity: 1;
		}
	}

	.newMainMenu__extraNav .newMainMenu__items--nested & {
		margin-left: $vwS20;

		//>=768px
		@include media($mediumNewLayout) {
			margin-left: 0;
		}
	}

	.newMainMenu__mainNav--mobile .newMenuItem__content & {
		margin: {
			bottom: $vwS15;
			top: $vwS15;
		}
	}

	.newMainMenu__submenu & {
		margin: {
			bottom: $vwS15;
			top: $vwS15;
		}

		//>=768px
		@include media($mediumNewLayout) {
			margin: {
				bottom: $vwM15;
				top: 0;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL20;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base2;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	.newMenuItem__items--nested & {
		@include font(newMainNavSecondLevel);
	}


	.newMainMenu__mainNav--mobile .newMenuItem__items--nested & {
		&--special {
			margin: {
				bottom: $vwS10;
				top: $vwS10;
			}
			@include font(headerLinks);
			border-bottom: 1px transparent solid;
			color: $colorHighlight;
			display: inline-block;
			margin-top: $base;

			&:hover {
				border-bottom: 1px $colorHighlight solid;
			}
		}
	}

	.newMainMenu__desktopMain & {
		@include font(newMainNavFirstLevel);
		width: 100%;
	}

	.newMainMenu__desktopSub & {
		@include font(newMainNavSecondLevel);

		&--special {
			@include font(headerLinks);
			border-bottom: 1px transparent solid;
			color: $colorHighlight;
			display: inline-block;
			margin-top: $base;

			&:hover {
				border-bottom: 1px $colorHighlight solid;
			}
		}
	}

	.newMainMenu__extraNav & {
		@include font(newExtraNavLink);
		padding-right: $vwS20;

		&--special {
			color: $colorHighlight;
		}

		//>=768px
		@include media($mediumNewLayout) {
			padding-right: $vwM20;
		}

		//>=1280px
		@include media($largeNewLayout) {
			padding-right: $vwL30;
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			padding-right: $base3;
		}
	}


	&__arrow {
		@include ease(transform opacity width, $durationBase, $easeBase);
		display: flex;
		float: right;
		height: 100%;
		opacity: 1;
		pointer-events: none;
		transform: rotate(90deg);
		width: $base * 1.5;

		//>=768px
		@include media($mediumNewLayout) {
			align-items: center;
			display: grid;
			grid-template-columns: 1fr auto;
			transform: none;
			width: $baseHalf;
		}

		//>=1280px
		@include media($largeNewLayout) {
			opacity: 0;

			.newMenuItem.js-active & {
				opacity: 1;
			}
		}

		.js-toggled & {
			transform: rotate(270deg);
		}

		// active link in desktop menu
		.newMenuItem.js-active & {
			width: 100%;
		}

		.newMenuItem__sectionTitle:hover & {
			@include media($largeNewLayout) {
				opacity: 1;
			}
		}

		.newMainMenu__mainNav--mobile & {
			display: block;
		}

		[data-type="svg"] {
			display: block;
			top: 0;

			> svg {
				fill: none;
			}
		}
	}

	&__arrowLine {
		@include ease(opacity, $durationBase, $easeBase);
		display: flex;
		background-color: $colorHighlight;
		//display: block;
		height: 1px;
		pointer-events: none;
		//width: 0;
		width: calc(100% + #{$base} * 0.5);
		opacity: 0;

		.newMenuItem.js-active & {
			width: calc(100% + #{$base} * 0.5);
			opacity: 1;
		}
	}

	&__content {
		@include ease(opacity visibility height padding-bottom, $durationBase, $easeBase);
		display: block;
		position: relative;
		height: auto;
		opacity: 1;
		overflow: hidden;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			@include ease(visibility, $durationBase, $easeBase);
			height: 100%;
			margin-left: $base1;
			overflow: visible;
			position: relative;
			width: staticColumnSize(5, 10, $newGutterSmall);
		}

		//>=1280px
		@include media($largeNewLayout) {
			margin-left: $base2;
			width: staticColumnSize(4, 7, $newGutterLarge);
		}

		&.js-collapsed {
			opacity: 0;
			position: absolute;
		}
	}

	&__item--group {
		border-top: solid 1px $colorSeparatoraAccordion;
		padding-bottom: $vwS15;

		//>=768px
		@include media($mediumNewLayout) {
			border-top: none;
			padding-bottom: 0;
		}

		&:last-child {
			border-bottom: solid 1px $colorSeparatoraAccordion;

			//>=768px
			@include media($mediumNewLayout) {
				border-bottom: none;
			}
		}
	}

	&__items {
		position: relative;
		overflow: hidden;

		&--nested {
			.newMenuItem--accordion & {
				margin: {
					bottom: 0;
					top: $vwS15;
				}
			}
		}
	}

	&__link,
	&__sectionTitle {
		@include new-base-link();
	}


	&__sectionTitle {
		@include font(newMainNavFirstLevel);
		@include staticColumns();
		align-items: center;
		cursor: pointer;
		margin-bottom: 0;
		padding-top: $vwS15;
		position: relative;
		width: 100%;
		//z-index: 10;

		//>=768px
		@include media($mediumNewLayout) {
			column-gap: $base1;
			display: grid;
			grid-template-columns: auto 1fr;
			margin-bottom: $vwM30;
			padding-top: 0;
		}

		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL30;
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base3;
		}

		// active link in desktop menu
		.newMenuItem.js-active & {
			color: $colorHighlight;
		}

		.newMenuItem__item--group:last-child & {
			//>=768px
			@include media($mediumNewLayout) {
				margin-bottom: 0;
			}
		}
	}
}
