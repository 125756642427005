.newIcon {
	&__accordionArrow {
		@include ease(transform, $duration3, $easeOutQuad);
		display: flex;
		justify-content: center;
		align-items: center;

		.js-toggled & {
			transform: rotate(180deg);
		}

		[data-type="svg"] span {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		[data-type="svg"] > canvas {
			width: $base1 + 0.1;
			height: $baseHalf;

			.accordion--smallVersion & {
				width: $base1 + 0.1;
				height: $baseHalf;
			}
			//>=768px
			@include media($largeNewLayout) {
				width: $base1 + 0.6;
				height: $base1 * 0.8;

				.accordion--smallVersion & {
					width: $base1 + 0.1;
					height: $baseHalf;
				}
			}
		}

		[data-type="svg"] > svg {
			fill: none;
			stroke: $colorHighlight;
			stroke-width: 2;
			stroke-linecap: round;
			stroke-linejoin: round;
			opacity: 1;
			width: $base1 + 0.1;
			height: $baseHalf;

			.accordion--smallVersion & {
				width: $base1 + 0.1;
				height: $baseHalf;
			}

			//>=768px
			@include media($largeNewLayout) {
				width: $base1 + 0.6;
				height: $base1 * 0.8;

				.accordion--smallVersion & {
					width: $base1 + 0.1;
					height: $baseHalf;
				}
			}
		}

		.block--vividColorVersion & {
			[data-type="svg"] > svg {
				stroke: $colorBase;
			}
		}

		.accordion__itemLarge & {
			position: absolute;
			right: 0;
		}

		.footer__menuWrapper--mobile & {
			position: relative;
		}
	}

	&__arrowPlus {
		display: block;
		@include ease(transform opacity, $duration3, $easeOutQuad);
		width: $newIconPlusWidth;
		height: $newIconPlusHeight;
		background: $colorBase;
		position: relative;

		.js-toggled & {
			&::after {
				transform: rotate(-90deg);
				opacity: 0;
			}
		}

		&::after {
			@include ease(transform opacity, $duration3, $easeOutQuad);
			transform: rotate(90deg);
			content: "";
			position: absolute;
			width: $newIconPlusWidth;
			height: $newIconPlusHeight;
			opacity: 1;
			background: $colorBase;
		}
	}
}
