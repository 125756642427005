.new-ugc {
	@include font(newBaseText);

	.block--darkColorVersion &,
	.block--lightColorVersion &,
	.block--veryLightColorVersion & {
		border-color: $newColorBorderBase;
	}

	.block--lightColorVersion &.newLargeList__listItem,
	.block--veryLightColorVersion &.newLargeList__listItem {
		border-color: $newColorBorderLight;
	}

	.block--vividColorVersion & {
		border-color: $newColorBorderVeryLight;
	}

	a {
		@include new-block-link();

		.block--darkColorVersion & {
			&:hover {
				border-bottom-color: $colorBgBase;
			}
		}

		.block--lightColorVersion &,
		.block--veryLightColorVersion & {
			&:hover {
				border-bottom-color: $colorBase;
			}
		}

		.block--vividColorVersion & {
			color: $colorBgBase;
			border-bottom-color: $colorBgBase;

			&:hover {
				color: $colorBase;
				border-bottom-color: $colorBase;
			}
		}

		.block--textHighlight & {
			border-bottom-color: transparent;
		}

		.block--vividColorVersion.block--textHighlight & {
			color: $colorBase;

			&:hover {
				border-bottom-color: $colorBase;
			}
		}

		.block--lightColorVersion.block--textHighlight &,
		.block--veryLightColorVersion.block--textHighlight & {
			&:hover {
				border-bottom-color: $colorHighlight;
			}
		}

		.accordion__itemLarge & {
			font-weight: $weightRegular;
			text-decoration: none;
			display: inline-block;
			border-bottom: 1px solid $colorBgBase;

			.block--vividColorVersion & {
				&:active,
				&:hover {
					color: $colorBase;
				}
			}

			.block--lightColorVersion &,
			.block--veryLightColorVersion & {
				border-bottom: 1px solid $colorBase;
			}
		}
	}

	em {
		.block--textHighlight & {
			@include font(newHeadingSecondV4);
		}
	}

	h1,
	h2,
	h3 {
		margin: {
			bottom: $vwS10;
			top: $vwS20;
		}

		//>=768px
		@include media($mediumNewLayout) {
			margin: {
				bottom: $vwM10;
				top: $vwM20;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin: {
				bottom: $vwL10;
				top: $vwL30;
			}
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin: {
				bottom: $base1;
				top: $base3;
			}
		}

		&:first-child {
			margin-top: 0;
		}
	}

	blockquote {
		.newTextBlock & {
			@include font(newMainNavFirstLevel);
			margin: $base0;
			display: block;
			position: relative;
			color: $colorHighlight;
			margin: {
				top: $vwS20;
				bottom: $vwS20;
			}

			//>=768px
			@include media($mediumNewLayout) {
				right: staticColumnPush(1, 7, $newGutterSmall);
				margin: {
					top: $vwM30;
					bottom: $vwM30;
				}
			}

			//>=1280px
			@include media($largeNewLayout) {
				right: staticColumnPush(1, 7, $newGutterLarge);
				margin: {
					top: $vwL50;
					bottom: $vwL50;
				}
			}

			//>=1440px
			@include media($xLarge2vNewLayout) {
				right: staticColumnPush(1, 6, $newGutterLarge);
			}

			//>=1680px
			@include media($xLargeNewLayout) {
				margin: {
					top: $base5;
					bottom: $base5;
				}
			}

			.block--vividColorVersion & {
				color: $colorBase;
			}
		}
	}

	h1 {
		@include font(newHeadingOne);
	}

	h2 {
		@include font(newHeadingSecond);
	}

	h3 {
		@include font(newHeadingThird);
	}

	hr {
		margin-top: $vwS20;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM20;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL30;
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			margin-top: $base3;
		}

		.block--darkColorVersion & {
			border-color: $newColorBorderDark;
		}

		.block--lightColorVersion &,
		.block--veryLightColorVersion & {
			border-color: $newColorBorderBase;
		}

		.block--vividColorVersion & {
			border-color: $newColorBorderVeryLight;
		}
	}

	li {
		margin: 0;
	}

	ol > li {
		padding-left: 0;
	}

	ol {
		margin: 0 0 0 $base2;
	}

	ul {
		margin: 0 0 0 $base2;
	}

	ul > li {
		position: relative;

		&::before {
			content: "\2022";
			position: absolute;
			top: 0;
			transform-origin: 0 50%;
			left: -$base2;
		}

		& > a {
			@include font(newTitleRichLink);
		}
	}

	// space before lists
	* + ol,
	* + ul {
		margin-top: $base * 2.5;

		@include media($smallLayout) {
			margin-top: $base * 3.5;
		}
	}

	// space between list items
	li + li {
		margin-top: $baseHalf;

		@include media($smallLayout) {
			margin-top: $base1;
		}
	}

	p {
		margin-bottom: $vwS10;

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL15;
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			margin-bottom: $base1 + $baseHalf;
		}

		&:last-child {
			margin-bottom: 0;
		}

		.accordion & {
			//@include font(newHeadingFifthV2);
			color: $colorBase;
			margin-top: $vwS10;

			//>=768px
			@include media($mediumNewLayout) {
				margin-top: $vwM05;
			}
			//>=1280px
			@include media($largeNewLayout) {
				margin-top: $vwL10;
			}
			//>=1680px
			@include media($xLargeNewLayout) {
				margin-top: $base1;
			}

			.block--darkColorVersion &,
			.block--vividColorVersion & {
				color: $colorBgBase;
			}
		}

		.accordion--smallVersion & {
			margin-top: $base0;
			@include font(newBaseText);
		}

		.newLarge__listItem & {
			@include font(newTitleAccordion);
		}
	}

	strong {
		@include font(newBoldBaseText);
	}

	span {
		.accordion__title & {
			@include font(newTitleAccordion);
			display: block;
		}
	}

	&--highlight {
		@include font(newHeadingSecondV2);

		p {
			margin-bottom: $vwS10;

			//>=768px
			@include media($mediumNewLayout) {
				margin-bottom: $vwM10;
			}
			//>=1280px
			@include media($largeNewLayout) {
				margin-bottom: $vwL20;
			}
			//>=1680px
			@include media($xLargeNewLayout) {
				margin-bottom: $base2;
			}
		}

		strong {
			@include font(newHeadingSecondV2);
			font-weight: $weightSemiBold;
		}
	}

	&.newLargeList__listItem {
		@include font(newHeadingSecond);

		p {
			@include font(newTitleAccordion);
		}

		strong {
			@include font(newHeadingSecond);
			font-weight: $weightSemiBold;
		}
	}

	&.newProfileHero__title {
		color: $colorHighlight;
		@include font(newProfileTitle);
		margin-top: $base1;
		position: relative;
		z-index: 2;

		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $base1 * 3.8;
			margin-left: -($base1 * 8.6);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base5;
			margin-left: -($base1 * 11.6);
		}
	}
}
