.newsletterModal {
	@include app-container-wide();
	bottom: 0;
	left: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	z-index: 3;

}
