.footer {
	position: relative;
	padding: {
		top: $vwS50;
		bottom: $vwS50;
	}
	background-color: $colorBgInput;
	//>=768px
	@include media($mediumNewLayout) {
		padding: {
			top: $vwM100;
			bottom: $vwM100;
		}
	}
	//>=1280px
	@include media($largeNewLayout) {
		padding: {
			top: $vwL150;
			bottom: $vwL150;
		}
	}
	//>=1680px
	@include media($xLargeNewLayout) {
		padding: {
			top: $base1 * 15;
			bottom: $base1 * 18.8;
		}
	}

	&.js-covered {
		@include app-covered();
	}

	&__container {
		@include app-newcontainer();
		position: relative;
	}

	&__content {
		position: relative;
		width: 100%;
		@include media($mediumNewLayout) {
			@include staticColumns();
		}
	}

	&__logo {
		position: relative;
		width: staticColumnPush;
		width: staticColumnSize(2, 6, $newGutterSmall);
		margin-bottom: $vwS30;

		[data-type="svg"] {
			display: block;
			height: $newLogoFooterHeight;
		}

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: $base0;
			width: staticColumnSize(2, 12, $newGutterSmall);
			height: auto;
			margin-left: staticColumnPush(1, 12, $newGutterSmall);

			[data-type="svg"] {
				height: $vwM50;
			}
		}

		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(2, 12, $newGutterLarge);
			margin-left: staticColumnPush(1, 12, $newGutterLarge);

			[data-type="svg"] {
				width: $vwL150;
				height: $vwL50;
			}
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(1, 12, $newGutterLarge);

			[data-type="svg"] {
				height: pxToRem(37);
				width: auto;
			}
		}
	}

	&__logoLink {
		text-decoration: none;
		color: $colorBase;
	}

	&__menuWrapper {
		width: 100%;
		display: none;

		//>=768px
		@include media($mediumNewLayout) {
			display: block;
			//padding-top: pxToRem(5);
			width: staticColumnSize(8, 12, $newGutterSmall);
			margin-left: staticColumnPush(1, 12, $newGutterSmall);
		}

		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(9, 12, $newGutterLarge);
			margin-left: $base0;
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(9, 12, $newGutterLarge);
			margin-left: staticColumnPush(1, 12, $newGutterLarge);
		}

		&--mobile {
			display: block;
			@include media($mediumNewLayout) {
				display: none;
			}
		}
	}

	&__sectionLinks {
		//>=768px
		@include media($mediumNewLayout) {
			display: flex;
			flex-wrap: wrap;
			row-gap: $vwM50;
			column-gap: $newGutterSmall;
		}
		//>=1280px
		@include media($largeNewLayout) {
			row-gap: $vwL50;
			column-gap: $newGutterLarge;
		}
		@include media($xLargeNewLayout) {
			row-gap: $base8 + 0.1;
		}
	}

	&__sectionLink {
		width: 100%;
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(4, 8, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(3, 9, $newGutterLarge);
		}
	}

	&__sectionLinkWrapper {
		text-decoration: none;
		color: $colorBase;
		display: none;

		@include media($mediumNewLayout) {
			display: block;
		}
	}

	&__sectionTitle {
		@include font(newSectionTitle);
		text-transform: uppercase;
	}

	&__links {
		margin-top: $vwS20;
		margin: {
			top: $vwS20;
		}
		//>=768px
		@include media($mediumNewLayout) {
			margin: {
				top: $vwM10;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL20;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base2;
		}

		.footer__menuWrapper--mobile & {
			@include ease(
				opacity visibility height padding-bottom,
				$durationBase,
				$easeBase
			);
			display: block;
			height: auto;
			opacity: 1;
			overflow: hidden;
			position: relative;

			&:focus {
				outline: 0;
			}

			&.js-collapsed {
				opacity: 0;
				position: absolute;
			}
		}

		.accordion__item & {
			margin-top: $base0;
		}
	}

	&__link {
		@include ease(color, $hoverDuration);
		@include font(newBaseSmall);
		background-color: ocean;
		text-decoration: none;
		color: $colorBase;
		display: inline-block;

		//>=768px
		@include media($mediumNewLayout) {
			//margin-top: $base * 0.7;
		}

		@include onHover() {
			color: $colorHighlight;
		}
	}

	&__linkWrapper {
		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $base * 0.7;
			line-height: 1;
		}

		&:first-child {
			@include media($mediumNewLayout) {
				margin-top: 0;
			}
		}

		.footer__menuWrapper--mobile & {
			display: flex;
			margin: {
				top: $vwS10;
			}

			&:first-child {
				margin: {
					top: $base0;
				}
			}
		}
	}
}
