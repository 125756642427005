@use "sass:math";

.contentPreview {
	width: staticColumnSize(5, 6, $newGutterSmall);
	margin-right: $newGutterSmall;

	//>=768px
	@include media($mediumNewLayout) {
		width: staticColumnSize(4, 8, $newGutterSmall);
	}
	//>=1280px
	@include media($largeNewLayout) {
		width: staticColumnSize(3, 9, $newGutterLarge);
		margin-right: $newGutterLarge;
	}

	&__categoryItem {
		@include font(newCategory);

		color: var(--color);
		margin-bottom: $vwS05;
		margin-top: $vwS10;

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: 0;
			margin-top: $vwM20;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL20;
			margin-top: $vwL10;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base2;
			margin-top: $base1;
		}

		time {
			white-space: nowrap;
		}
		span {
			color: var(--highlight-color);
		}
	}

	//for new-content-list
	&__contentList {
		margin: {
			left: 0;
			right: 0;
		}
		width: 100%;
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(4, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(3, 9, $newGutterLarge);
		}
	}

	&__iconPlay {
		position: absolute;
		top: $base1;
		left: $base1;
		z-index: 99;
		cursor: pointer;

		[data-type='svg'] > svg {
			fill: none;
			height: $base2;
			width: $base2;
			//>=1280px
			@include media($largeNewLayout) {
				height: $base1 * 4;
				width: $base1 * 4;
			}
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;

		&--nft{
			position: initial;
		}
	}

	&__wrapperMedia {
		@include aspectRatio(math.div(3, 2));
		display: block;
		text-decoration: none;
		overflow: hidden;
		position: relative;

		&--nft{
			aspect-ratio: initial;
			&::before{
				padding: 0;
			}
		}
	}

	&__img {
		@include ease(transform, $duration3, $easeOutQuad);
		@include objectFit(cover);
		min-height: 100%;
		position: relative;
	}

	&__metadata {
		span + span {
			&:before {
				content: ' | ';
				color: var(--highlight-color);
			}
		}
	}

	&__itemContent, &__metadata {
		@include font(newBaseSecond);
		color: var(--color);
		display: block;
		margin-top: $vwS05;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL10;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base1;
		}
	}
	&__itemContent {
		@include media($largeNewLayout) {
			opacity: 0.8;
		}
	}

	&__itemLink {
		@include font(newLinkSlider);
		@include ease(color, $hoverDuration);
		position: relative;
		text-decoration: none;
		color: var(--highlight-color);
		display: inline;
		border-bottom: {
			color: transparent;
			style: solid;
			width: 1px;
		}
		@include onHover() {
			border-bottom-color: var(--highlight-color);
		}
	}

	&__itemLinksBlock {
		@include font(newLinkSlider);
		color: var(--highlight-color);
		line-height: $base2;
		margin-top: $vwS05;

		//>=1024px
		@include media($mediumLayout) {
			margin-top: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL10;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base1;
		}
	}

	&__itemLinksSpan:last-child {
		display: none;
	}

	&__itemTitle {
		@include font(newTitleSlider);
		@include ease(color background-color, $hoverDuration);
		color: var(--color);
	}

	&__linkWrapper {
		text-decoration: none;
		display: block;

		@include onHover() {
			.contentPreview__itemTitle {
				box-shadow: 0 -1.3em 0 0 var(--highlight-color) inset, -0.1em 0.16rem 0 0 var(--highlight-color), -0.1em 0 0 0 var(--highlight-color), 0.1em 0.16rem 0 0 var(--highlight-color), 0.1em 0 0 0 var(--highlight-color), 0 0 0 0 var(--highlight-color), -0.1em -0.1em 0 0 var(--highlight-color), -0.1em -0.1em 0 0 var(--highlight-color), 0.1em -0.1em 0 0 var(--highlight-color);
				color: var(--highlightComplement-color);
			}

			.contentPreview__img {
				transform: scale($scaleImg);
			}
		}

		&--nft{
			@include onHover() {}
		}
	}

	&__media {
		//aspect-ratio: 3 / 2;
		//@include aspectRatio(math.div(3, 2));
		overflow: hidden;
	}

	&__video {
		width: 100%;
		height: 100%;
		//left: 0;
		position: absolute;
		//top: 0;
		left: 50%;
		top: 50%;
		transform: translate3d(-50%, -50%, 0);
	}


	&__videoContent {
		@include ease(transform, $duration3, $easeOutQuad);
		position: relative;
	}

	&__nftButton {
		@include font(newSectionTitle);
		text-transform: uppercase;
		text-align: center;
		color: #000000;
		background: #F0F0F0;
		padding: $base1+0.5 $base3;
		margin-top: 1.2vw;
		width: 100%;
		cursor: pointer;
		border: 1px solid $lightColorBg;
		transition: 0.6s ease-in;
		&:hover{
			background: $colorBase;
			border: 1px solid $colorBase;
			color: $colorBgBase;
		}
	}

	@include media ($smallLayout) {
		&__nftButton {
			padding: 0.9vw 1.8vw;
			width: auto;
		}
	}
}
