.newPersonsList {
	@include staticColumns();
	flex-direction: column;


	&__category,
	&__separator {
		width: 100%;
	}

	&__category {
		@include font(newCategory);
		margin-bottom: $vwS20;

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: $vwM30;
		}

		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL50;
			margin-left: staticColumnPush(1, 12, $newGutterLarge);
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base5;
		}
	}

	&__items {
		@include dynamicColumns(0, 0);
		//justify-content: flex-start;

		@include media($mediumNewLayout) {
			@include dynamicColumns($newGutterSmall, 0);
		}

		//>=1280px
		@include media($largeNewLayout) {
			@include dynamicColumns($newGutterLarge, 0);
		}
	}

	&__itemsContainer {
		margin-bottom: 0;
		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: -$vwM50;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: -$vwL100;
			margin-left: staticColumnPush(1, 12, $newGutterLarge);
			width: staticColumnSize(10, 12, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: -$base10;
		}
	}

	&__separator {
		background-color: $colorBgInput;
		height: 1px;
		margin: {
			bottom: $vwS20;
			top: $vwS20;
		}

		//>=768px
		@include media($mediumNewLayout) {
			display: none;
		}

		&:last-child {
			display: none;
		}
	}
}
