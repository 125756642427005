.slideshowNavigation {
	margin-top: $slideShowNavigationTopSpacingSmall;
	width: auto;

	@include media($mediumLayout) {
		margin-top: $slideShowNavigationTopSpacingLarge;
	}

	&__arrow {
		position: absolute;
		top: 0;
		left: -$base7;

		&--next {
			left: auto;
			right: -$base7;
		}
	}

	&__arrows {
		display: flex;
		display: none;
		margin-bottom: $base;
		position: absolute;
		top: calc(50% - (#{$base4} + #{$slideShowNavigationTopSpacingSmall} + #{$slideShowNavigationDotSizeSmall}));
		width: 100%;

		.block--slideshow.block--slideshowStyleAlternative & {
			display: none;
			top: calc(50% - (#{$base4} + #{$slideShowNavigationTopSpacingLarge} + #{$slideShowNavigationDotSizeLarge}));
		}

		@include media($columnsBreakpoint) {
			display: block;
		}
	}

	&__triggers {
		display: flex;
		margin-bottom: $base3;
		width: auto;
		justify-content: center;
		line-height: 0;

		@include media($columnsBreakpoint) {
			margin-bottom: $base4;
		}
	}


	&__trigger {
		flex-grow: 0;

		&:not(:first-child) {
			margin-left: $base * 1.5;

			@include media($columnsBreakpoint) {
				margin-left: $base3;
			}
		}
	}
}
