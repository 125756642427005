@keyframes loader-animation {
	0% {
		transform: scale(1);
	}

	80% {
		transform: scale(0.75);
	}

	100% {
		transform: scale(1);
	}
}
