.hamburgerIcon {
	display: block;
	height: $hamburgerHeight;
	overflow: hidden;
	position: relative;
	width: $hamburgerWidth;

	&__part {
		@include ease(left width transform opacity, $durationBase * 0.5, $easeBase);
		background-color: currentColor;
		display: block;
		height: 2px;
		opacity: 1;
		position: absolute;
		transform: rotate(0);
		width: $hamburgerWidth;

		&:nth-child(1) {
			left: 0;
			top: 0;
			transform-origin: left center;

			.newMenuToggler__toggler.js-toggled & {
				left: 3px;
				transform: rotate(45deg);
			}
		}

		&:nth-child(2) {
			right: 0;
			top: 9px;
			transform-origin: left center;
			width: $hamburgerWidthShort;

			.newMenuToggler__toggler.js-toggled & {
				width: 0;
			}
		}

		&:nth-child(3) {
			bottom: 0;
			left: 0;
			transform-origin: left center;

			.newMenuToggler__toggler.js-toggled & {
				left: 3px;
				transform: rotate(-45deg);
			}
		}
	}
}
