@use 'sass:math';

.newGallerySliderItem {
	position: relative;
	width: auto;
	margin-right: $newGutterSmall;

	&:last-child {
		margin-right: $base0;
		padding-right: $newContainerPaddingSmall;

		//>=768px
		@include media($mediumNewLayout) {
			padding-right: $base3;
		}

		// 1280px
		@include media($largeNewLayout) {
			padding-right: $base2;
		}

		//1440px
		@include media($xLarge2vNewLayout) {
			padding-right: $base2;
		}
	}

	&--default {
		@include media($largeNewLayout) {
			margin-right: $newGutterLarge;
		}
	}

	&--imageText {
		@include media($mediumLayout) {
			margin-right: pxToRem(56);
		}

		//1280px
		@include media($largeNewLayout) {
			margin-right: pxToRem(77);
		}

		//1680px
		@include media($xLargeNewLayout) {
			margin-right: pxToRem(100);
		}
	}

	// 1024px
	@include media($mediumLayout) {
		width: auto;
		max-width: unset;
	}

	&__wrapperMedia {
		position: relative;
		display: table;
	}

	&__img {
		height: 27vh;
		max-width: 100%;
		width: auto;

		@include media($smallLayout) {
			height: 60vh;
		}

		// 1024px
		@include media($mediumLayout) {
			height: 60vh;
			object-fit: unset;
		}

		.newGallerySliderItem--tall & {
			height: 80vh;
		}
	}

	&__media {
		overflow: hidden;

		.newGallerySliderItem--withText & {
			display: block;

			@include media($mediumLayout) {
				display: flex;
			}
		}
	}

	&__itemText {
		margin-top: $vwS05;
		margin-bottom: $vwS10;
		z-index: 99;
		position: relative;
		overflow-wrap: break-word;
		display: table-caption;
		caption-side: bottom;

		// 1024px
		@include media($mediumLayout) {
			margin-top: $vwM05;
		}

		//1280px
		@include media($largeNewLayout) {
			margin-top: $vwL05;
		}

		.text {
			@include font(newAltTextEditor);
		}
	}

	&__wrapperText {
		z-index: 99;
		@include font(newBase);

		h2 {
			@include font(newTitleSlider);
		}

		&--mobile {
			margin-top: $vwS10;
			// 1024px
			@include media($mediumLayout) {
				display: none;
			}
		}
	}

	&__text {
		position: relative;
		display: none;

		@include media($mediumLayout) {
			display: flex;
			width: pxToRem(400);
			margin-left: $gutterMedium;
			align-items: center;
			height: 60vh;

			.newGallerySliderItem--tall & {
				height: 80vh;
			}
		}
	}
}
