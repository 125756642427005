.accordion {
	position: relative;
	width: 100%;

	//>=768px
	@include media($mediumNewLayout) {
		@include staticColumns();
		align-items: baseline;
	}
	//>=1280px
	@include media($largeNewLayout) {
		width: staticColumnSize(11, 12, $newGutterLarge);
	}

	&__body {
		position: relative;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}

	&__headerWrapper {
		width: 100%;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(4, 12, $newGutterSmall);
			height: auto;
			justify-content: flex-end;
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(3, 11, $newGutterLarge);
		}

		&--small {
			//>=768px
			@include media($mediumNewLayout) {
				width: staticColumnSize(4, 11, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				width: staticColumnSize(3, 10, $newGutterLarge);
			}
			//>=1680px
			@include media($xLargeNewLayout) {
				width: staticColumnSize(3, 9, $newGutterLarge);
			}
		}
	}

	&__item {
		@include ease(height, $durationBase, $easeBase);
		border-bottom: 1px solid $colorSeparatoraAccordion;
		overflow: hidden;
		padding: {
			bottom: $vwS15;
			top: $vwS10;
		}
		position: relative;

		//>=768px
		@include media($mediumNewLayout) {
			padding: {
				bottom: $vwM15;
				top: $vwM10;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				bottom: $vwL30;
				top: $vwL20;
			}
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding: {
				bottom: $mediumSpacing;
				top: $base2;
			}
		}

		&:nth-child(1) {
			border-top: 1px solid $colorSeparatoraAccordion;
		}

		.block--vividColorVersion & {
			border-bottom: 1px solid $colorBgBase;

			&:nth-child(1) {
				border-top: 1px solid $colorBgBase;
			}
		}

		.footer__menuWrapper--mobile & {
			border-bottom: 1px solid $colorBase;
			padding: {
				bottom: $vwS;
				top: $vwS;
			}

			&:nth-child(1) {
				border-top: 1px solid $colorBase;
			}
		}
	}

	&__items {
		margin-left: 0;
		width: 100%;
		//>=768px
		@include media($mediumNewLayout) {
			margin-left: $newGutterSmall;
			width: staticColumnSize(8, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(8, 11, $newGutterLarge);
			margin-left: $newGutterLarge;
		}

		&--small {
			//>=768px
			@include media($mediumNewLayout) {
				width: staticColumnSize(7, 11, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				width: staticColumnSize(7, 10, $newGutterLarge);
			}
			//>=1680px
			@include media($xLargeNewLayout) {
				width: staticColumnSize(6, 9, $newGutterLarge);
			}
		}
	}

	.js-toggled {
		color: $colorHighlight;
		opacity: 1;

		.block--vividColorVersion & {
			color: $colorBgBase;
		}
	}

	&__leftContent {
		overflow: hidden;
		width: 100%;

		@include media($mediumNewLayout) {
			width: staticColumnSize(6, 8, $newGutterSmall);
		}
		@include media($largeNewLayout) {
			width: staticColumnSize(4, 8, $newGutterLarge);
		}

		&--displayNone {
			display: none;
		}
	}

	&__rightContent {
		width: 100%;

		@include media($mediumNewLayout) {
			width: staticColumnSize(6, 8, $newGutterSmall);
		}

		@include media($largeNewLayout) {
			margin-left: $newGutterLarge;
			width: staticColumnSize(4, 8, $newGutterLarge);
		}

		&--resetOffset {
			margin-left: $base0;
		}
	}

	&__subTitle {
		@include font(newSubTilteAccordion);
		color: $colorBgBase;
		margin-top: $vwS30;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM20;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: 0;
		}

		.block--lightColorVersion &,
		.block--veryLightColorVersion & {
			color: $colorBase;
		}
	}

	&--smallVersion {
		//>=768
		@include media($mediumNewLayout) {
			width: staticColumnSize(11, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(10, 12, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(9, 12, $newGutterLarge);
		}
	}

	&__textContent {
		@include ease(
			opacity visibility height padding-bottom,
			$durationBase,
			$easeBase
		);
		display: block;
		height: auto;
		opacity: 1;
		overflow: hidden;
		padding: {
			bottom: $vwS30;
			top: $vwS30;
		}
		width: 100%;

		.accordion--smallVersion & {
			padding: {
				top: $vwS20;
				bottom: $vwS20;
			}
		}

		@include media($mediumNewLayout) {
			padding: {
				bottom: $vwM50;
				top: $vwM30;
			}

			.accordion--smallVersion & {
				padding: {
					top: $vwM30;
					bottom: $vwM30;
				}
			}
		}
		//1280px
		@include media($largeNewLayout) {
			padding: {
				bottom: $vwL50;
				top: $vwL50;
			}

			.accordion--smallVersion & {
				padding: {
					top: $vwL30;
					bottom: $vwL30;
				}
			}
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			padding: {
				bottom: $base5;
				top: $base5;
			}

			.accordion--smallVersion & {
				padding: {
					top: $base3;
					bottom: $base3;
				}
			}
		}

		&:focus {
			outline: 0;
		}

		&.js-collapsed {
			opacity: 0;
			position: absolute;
		}

		.accordion__item--large & {
			width: 100%;

			@include media($smallLayout) {
				width: staticColumnSize(7, 8, $newGutterSmall);
			}

			@include media($largeNewLayout) {
				width: staticColumnSize(7, 8, $newGutterLarge);
			}

			@include media($xLarge2vNewLayout) {
				width: staticColumnSize(6, 8, $newGutterLarge);
			}
		}

		&--footer {
			padding: {
				bottom: $vwS20;
				top: $vwS20;
			}
		}
	}

	&__title {
		@include font(newTitleAccordion);
		@include ease(color, $hoverDuration);
		color: $colorBgBase;
		display: flex;
		justify-content: space-between;
		align-items: center;
		cursor: pointer;

		@include onHover() {
			color: $colorHighlight;
		}

		.block--vividColorVersion & {
			@include onHover() {
				color: $colorBase;
			}
		}

		.footer__menuWrapper--mobile & {
			@include font(newSectionTitle);
			text-transform: uppercase;
			color: $colorBase;
			opacity: 1;
			display: grid;
			grid-template-columns: 1fr 0fr;
		}

		.accordion--smallVersion & {
			@include font(newTitleRichLink);
			font-weight: $weightRegular;
		}

		.block--lightColorVersion &,
		.block--veryLightColorVersion & {
			color: $colorBase;
		}

		/* stylelint-disable-next-line no-duplicate-selectors */
		.accordion--smallVersion &,
		.accordion--largeVersion & {
			&.js-toggled {
				.block--darkColorVersion & {
					color: $colorBgBase;
				}
			}

			.block--darkColorVersion & {
				@include onHover() {
					color: $colorHighlight;
				}
			}
		}
	}
}
