@use "sass:math";

.video {
	position: relative;
	z-index: 2;

	@mixin hideVideoCoverWhenReady() {
		@include ease(opacity visibility, $duration2);
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.video.js-loading & {
			@include fadeOut();
		}

		.video.js-loaded & {
			display: none;
		}
	}

	&--overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

		.block--video.js-loaded & {
			@include fadeOut();
		}
	}

	&__caption {
		@include font(detailSmall);
		margin-top: $base;

		.blocks:not(.blocks--nested) > .block--video.block--hSizeFull & {
			@include app-container();
		}
	}

	&__content {
		.video:not(.video--newOverlay) & {
			@include aspectRatio($videoRatio);
			position: relative;
		}

		// useful to test the source switch of self-hosted video player
		// @include media('portrait') {
		// 	@include aspectRatio(1 / $videoRatio);
		// }
	}

	&__footer {
		// @include app-container-wide();
		margin-bottom: 0;
		padding-top: $base10;
	}

	&__icon {
		@include ease(color opacity, $hoverDuration);
		color: $colorInverted;
		left: calc(50% - #{$base * 2.5});
		margin-top: -$base * 2.5;
		position: absolute;
		top: 50%;

		@include onHover() {
			opacity: 0.7;
		}

		@include media($smallLayout) {
			margin-top: -$base * 4.5;
			left: calc(50% - #{$base * 3.5});
		}

		[data-type="svg"] {
			width: $base5;
			height: $base5;

			@include media($smallLayout) {
				height: $base7;
				width: $base7;
			}
		}

		&--newIcon {
			@include ease(color, $hoverDuration);
			color: $colorBgBase;

			@include onHover() {
				opacity: 1;
				color: $colorHighlight;
			}
		}
	}

	&__img {
		@include hideVideoCoverWhenReady();
		@include objectFit(cover);

		.contentPreview &,
		.newVideo & {
			height: auto;
			min-height: 100%;
		}
	}

	&__link {
		@include hideVideoCoverWhenReady();
		cursor: pointer;
		color: currentColor;

		&::before {
			@include ease(background-size, 1s, $easeOutQuad);
			background-origin: content-box;
			background-repeat: no-repeat;
			content: "";
			height: 100%;
			left: 0;
			//max-width: unset;
			position: absolute;
			top: 0;
			width: 100%;
			//z-index: 20;

			@include media($jigsawLayout) {
				//float: right;
				//padding-left: staticColumnSize(3, 12, $gutterLarge);
			}

			.blocks:not(.blocks--nested) > .block--rightAlignedLinks & {
				@include media($jigsawLayout) {
					//padding-right: staticColumnSize(3, 12, $gutterLarge);
					//	padding-left: 0;
				}
			}

			// reference values grabbed from the design
			$w: 1280;
			$h: 828;
			// the additional pixel is needed for retina display that have rounding errors in some cases
			$min: -1px;
			$max: calc(100% + 1px);

			$animationStart: 500%;

			background-image: linear-gradient($colorBgBase 0%, $colorBgBase 100%);
			background-position: $min $min;
			background-size: (math.div(138, $w) * 100%) (math.div(322, $h) * 100%);

			.video:hover & {
				background-size: 100% (math.div(322, $h) * 100%);
				@include ease(background-size, 0.6s, $easeOutQuad);
				background-size: (math.div($jigsawHover * 138, $w) * 100%)
					(math.div(322, $h) * 100%);
			}

			&:not(.js-intersectedJirgsaw) & {
				background-size: 100% (math.div(322, $h) * 100%);
			}

			.fullVideo &,
			.newVideo & {
				display: none;
			}

			.newProfileHero &,
			.newEditorialHero & {
				content: initial;
			}
		}

		.newMainTeaser & {
			display: none;
		}
	}

	.fullVideo & {
		.video--overlay,
		.videoPlayer__embed,
		.video__img {
			object-fit: fill;
		}
	}

	.contentPreview & {
		.video__link::before {
			content: inherit;
		}

		.video__icon {
			position: relative;
			top: $base1;
			left: $base1;
		}

		[data-type="svg"] {
			height: $base2;
			width: $base2;
			display: none;
			//>=1280px
			@include media($largeNewLayout) {
				height: $base1 * 4;
				width: $base1 * 4;
			}
		}
	}

	.contentPreview__noIcon & {
		.video__content {
			[data-type="svg"] {
				display: none;
			}
		}
	}
}
