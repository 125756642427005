.fullVideo {
	&__content {
		display: grid;
		gap: $vwS10;
		grid-template-columns: 1fr;
		margin-left: 0;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			gap: $vwM20;
			margin-left: staticColumnPush(1, 12, $newGutterSmall);
			width: staticColumnSize(10, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			gap: $vwL50;
			grid-template-columns: repeat(2, 1fr);
			margin-left: staticColumnPush(1, 12, $newGutterLarge);
			width: staticColumnSize(10, 12, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			gap: $base5;
		}
	}

	&__heading1,
	&__heading2 {
		@include font(newCategory);
	}

	&__heading1 {
		order: 1;
	}

	&__heading2 {
		order: 3;
		margin-right: 0;

		//>=1280px
		@include media($largeNewLayout) {
			margin-right: -$base1;
			order: 2;
			text-align: right;
		}
	}

	&__videoWrapper {
		grid-column: 1 / 1;
		order: 2;
		position: relative;

		//>=1280px
		@include media($largeNewLayout) {
			grid-column: 1 / span 2;
			order: 3;
		}
	}
}
