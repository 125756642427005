.links {
	display: flex;
	margin-left: -$base2; // horizontal gutter
	margin-top: ($base2 - $base2);	// real marginTop - vertical gutter
	flex-wrap: wrap;

	//margin-top: 25%;

	&__anchor {
		display: block;
		position: relative;
		top: -250px;
		visibility: hidden;
	}

	.block--hAlignBegin & {
		justify-content: flex-start;
	}

	.block--hAlignEnd & {
		justify-content: flex-end;
	}

	.block--hAlignCenter & {
		justify-content: center;
	}

	.block__additionalContent & {
		background-color: $colorBgBase;
		display: block;
		margin-left: 0;
		padding-bottom: 0;//$base2;
		position: relative;

		@include media($largeLayout) {
			display: none;
		}
	}

	.block--breadcrumb-links & {
		display: flex;
		justify-content: center;
	}

	.block--highlight-header & {
		display: flex;
		justify-content: center;
	}

	.block--teaser & {
		background-color: $colorBgBase;
		margin-left: 0;
		padding-bottom: $base2;
		position: relative;
		display: none;

		@include media($jigsawLayout) {
			flex-direction: column;
			flex-wrap: nowrap;
			display: flex;
			height: 100%;
			justify-content: center;
			left: 0;
			position: absolute;
			top: 0;
			width: staticColumnSize(4, 12, $gutterMedium, $containerPaddingSmall - $gutterMedium);
			padding-bottom: 0;
		}

		@include media($largeLayout) {
			width: staticColumnSize(3, 12, $gutterLarge, $containerPaddingLarge - $gutterLarge);
		}


		// the additional pixel is needed for retina display that have rounding errors in some cases
		&::before {
			background-color: inherit;
			bottom: -1px;
			content: '';
			left: -1px;
			position: absolute;
			right: -1px;
			top: -1px;
			z-index: 0;
		}
	}

	.blocks:not(.blocks--nested) > .block--rightAlignedLinks & {
		@include media($jigsawLayout) {
			left: auto;
			right: 0;
		}
	}

	.block--teaser:nth-child(2n +1) & {
		//padding-left: $containerPaddingSmall;

		@include media($smallLayout) {
			//padding-left: $base5;
		}
	}

	.block--content-teaser & {
		justify-content: center;
	}

	&__item {
		font-size: initial;
		line-height: initial;
		margin-left: $base;
		margin-top: $baseHalf;
		text-align: right;

		&:first-of-type {
			margin-left: 0;
		}

		.blocks:not(.blocks--nested) > .block--rightAlignedLinks & {
			@include media($jigsawLayout) {
				text-align: left;
			}
		}

		.block__additionalContent & {
			width: auto;
			display: inline-block;

			&:first-of-type::before {
				display: none;
			}

			&::before {
				content: '';
				width: $baseHalf;
				height: $baseHalf;
				border-radius: 50%;
				background-color: $colorBase;
				margin-left: 0;
				margin-right: $base;
				display: inline-block;
			}
		}

		.block--highlight-header & {
			&:not(:last-child) {
				&::after {
					@include font(highlightMedium);
					content: '/';
					color: $colorBase;
					display: inline-block;
					margin-left: 0.2em;
					margin-right: 0.2em;
				}
			}
		}

		.block--breadcrumb-links & {
			&::before {
				color: $colorBase;
				content: '/';
				margin-right: $base;
				display: inline-block;
			}

			&:first-of-type::before {
				display: none;
			}
		}

		.block--teaser & {
			margin-left: 0;
			margin-top: 0;
		}
	}
}
