.newImageGallery {
	position: relative;
	width: 100%;
	z-index: 1;

	&__arrowButton {
		cursor: grab;
		display: none;
		left: 0;
		position: absolute;
		top: 0;
		transform: translate(-50%, -50%);
		z-index: 22;
	}

	&__arrowButtonIcon {
		[data-type='svg'] > svg {
			fill: none;
		}

		.textSlider__arrowButton.js-rotate & {
			transform: scaleX(-1);
		}
	}

	&__category {
		@include font(newCategory);
		left: 0;
		position: absolute;
		text-align: left;
		top: 0;
		width: staticColumnSize(4, 6, $newGutterSmall);

		//>=768px
		@include media($mediumLayout) {
			margin-bottom: 0;
			padding-right: $categoryMediumPadding;
			text-align: right;
			width: staticColumnSize(4, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-right: $vwL20;
			width: staticColumnSize(3, 12, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base2;
			padding-right: $base2;
		}
	}

	&__categoryWrapper {
		@include app-newcontainer();
		position: absolute;
		top: 0;
		display: none;

		//>=768px
		@include media($mediumLayout) {
			position: relative;
		}

		// 1024px
		@include media($mediumLayout) {
			display: block;
		}
	}

	&__categoryWrapperInner {
		@include staticColumns();
		position: relative;
	}

	&__items {
		@include staticColumns();
		justify-content: left;
		width: 100%;

		&::after {
			content: '';
			display: inline-block;
			height: $base10;

			@include media($largeNewLayout) {
				min-width: $newContainerPaddingLarge - $newGutterLarge;
			}

			@include media($xLargeNewLayout) {
				min-width: calc((100vw - #{$newContainerMaxWidth} + #{$newGutterLarge}) * 0.5);
			}
		}
	}

	&__itemsContainer {
		position: relative;
		width: 100%;

		//>=768px
		@include media($mediumLayout) {
			margin-left: staticColumnPush(4, 12, $newGutterLarge);
			width: staticColumnSize(8, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-left: staticColumnPush(3, 12, $newGutterLarge);
			width: staticColumnSize(9, 12, $newGutterLarge);
		}

		&--withText {
			width: 100%;
			margin-left: $base0;
		}
	}

	&__wrapper {
		@include app-newcontainer();
		padding-top: 0;
	}

	&__wrapperInner {
		@include staticColumns();
		flex-direction: column;
		position: relative;
		background-color: rgba(245, 222, 179, 0);

		//>=768px
		@include media($mediumLayout) {
			flex-direction: row;
		}
	}

	&__wrapperProgress {
		@include app-newcontainer();
		@include staticColumns();
		display: flex;
		justify-content: space-between;
		align-items: center;

		background-color: inherit;

		margin-bottom: $vwS20;

		&--withImageOnly {
			@include media($mediumLayout) {
				justify-content: end;
			}
		}

		@include media($mediumLayout) {
			margin-bottom: $vwM20;
			align-items: baseline;
		}

		//>=1680px
		@include media($largeNewLayout) {
			margin-bottom: $vwL15;
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base2;
		}
	}

	&__header {
		@include font(newCategory);

		.newImageGallery__wrapperProgress--withImageOnly & {
			// 1024px
			@include media($mediumLayout) {
				display: none;
			}
		}
	}

	&__progressBar {
		background: $colorBase;
		height: pxToRem(1);
		position: relative;
		width: staticColumnSize(2, 6, $newGutterSmall);

		.block--darkColorVersion &,
		.block--vividColorVersion & {
			background: $colorBgBase;
		}

		//>=768px
		@include media($mediumLayout) {
			width: staticColumnSize(2, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(2, 12, $newGutterLarge);
		}
	}

	&__progressInner {
		background-color: $colorBase;
		display: block;
		height: $newProgressInnerHeight;
		position: relative;
		top: -$newProgressBarHeight;

		.block--darkColorVersion &,
		.block--vividColorVersion & {
			background: $colorBgBase;
		}
	}

	&__wrapperScroll {
		cursor: grab;
		-ms-overflow-style: none;
		overflow-x: scroll;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
}
