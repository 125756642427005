@use "sass:math";

$htmlFontSize: 62.5%;

$weightExtraLight: 200; // available for: Brando Italic
$weightLight: 300; // available for: Brando Italic
$weightRegular: 400; // available for: Brando, Brando Italic, WorkSans
$weightMedium: 500; // available for: WorkSans
$weightSemiBold: 600; // available for: WorkSans
$weightBold: 700; // not available

// TODO: the correct values are only desktop currently, mobile needs to be updated in future
// NOTE: fontsizes are defined by:
// - generical name (detail, base, highlight)
// - strongest visual difference (italic, bold, alternate fontfamily)
// There sorted by fontsize
$fontSizes: (
	detailSmall: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(10),
		font-weight: $weightRegular,
		letter-spacing: 0.028rem,
		line-height: 12 * 0.1,
		variants: (
			$smallLayout: (
				font-size: pxToRem(12),
				letter-spacing: 0.2rem,
				line-height: math.div(14, 12),
			),
		),
	),
	detail: (
		font-family: $fontFamilyAlt,
		font-weight: $weightRegular,
		variants: (
			$smallLayout: (
				font-size: pxToRem(14),
				letter-spacing: 0.05rem,
				line-height: math.div(22, 14),
			),
		),
	),
	detailMedium: (
		font-family: $fontFamilyAlt,
		font-weight: $weightMedium,
		font-size: pxToRem(14),
		letter-spacing: 0.086rem,
		line-height: math.div(16, 12),
		variants: (
			$smallLayout: (
				font-size: pxToRem(14),
				letter-spacing: 0.035rem,
				line-height: math.div(22, 14),
			),
		),
	),
	detailBold: (
		font-family: $fontFamilyAlt,
		font-weight: $weightBold,
		variants: (
			$smallLayout: (
				font-size: pxToRem(14),
				letter-spacing: 0.05rem,
				line-height: math.div(22, 14),
			),
		),
	),
	detailSemiBold: (
		font-family: $fontFamilyAlt,
		font-weight: $weightSemiBold,
		font-size: pxToRem(12),
		letter-spacing: 0.086rem,
		line-height: math.div(16, 12),
		variants: (
			$smallLayout: (
				font-size: pxToRem(14),
				letter-spacing: 0.1rem,
				line-height: math.div(22, 14),
			),
		),
	),
	highlight: (
		font-family: $fontFamilyAlt,
		font-weight: $weightRegular,
		variants: (
			$smallLayout: (
				font-size: pxToRem(16),
				letter-spacing: 0.5rem,
				line-height: math.div(24, 16),
			),
		),
	),
	highlightMedium: (
		font-family: $fontFamilyAlt,
		font-weight: $weightMedium,
		font-size: pxToRem(14),
		letter-spacing: 0.263rem,
		line-height: math.div(18, 14),
		text-transform: uppercase,
		variants: (
			$smallLayout: (
				font-size: pxToRem(16),
				letter-spacing: 0.3rem,
				line-height: math.div(24, 16),
			),
		),
	),
	baseSmall: (
		font-family: $fontFamilyMain,
		font-weight: $weightRegular,
		font-size: pxToRem(14),
		letter-spacing: 0.03rem,
		line-height: math.div(18, 14),
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				line-height: math.div(26, 18),
			),
		),
	),
	baseSmallItalic: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(14),
		font-style: italic,
		font-weight: $weightRegular,
		letter-spacing: 0.03rem,
		line-height: math.div(18, 14),
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				letter-spacing: 0.03rem,
				line-height: math.div(26, 18),
			),
		),
	),
	baseSmallItalicAlternate: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(16),
		font-style: italic,
		font-weight: $weightRegular,
		letter-spacing: 0.05rem,
		line-height: math.div(24, 16),
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				letter-spacing: 0.044rem,
				line-height: math.div(26, 18),
			),
		),
	),
	baseSmallAlternate: (
		font-family: $fontFamilyAlt,
		font-weight: $weightRegular,
		font-size: pxToRem(14),
		letter-spacing: 0.039rem,
		line-height: math.div(18, 14),
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				letter-spacing: 0.03rem,
				line-height: math.div(26, 18),
			),
		),
	),
	baseSmallMedium: (
		font-family: $fontFamilyAlt,
		font-weight: $weightMedium,
		font-size: pxToRem(16),
		letter-spacing: 0.02rem,
		line-height: math.div(24, 16),
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				letter-spacing: 0.03rem,
				line-height: math.div(26, 18),
			),
		),
	),
	baseSmallBold: (
		font-family: $fontFamilyAlt,
		font-weight: $weightBold,
		font-size: pxToRem(14),
		letter-spacing: 0.039rem,
		line-height: math.div(18, 14),
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				letter-spacing: 0.05rem,
				line-height: math.div(26, 18),
			),
		),
	),
	baseSmallSemiBold: (
		font-family: $fontFamilyAlt,
		font-weight: $weightSemiBold,
		font-size: pxToRem(14),
		letter-spacing: 0.018rem,
		line-height: math.div(18, 14),
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				letter-spacing: 0.023rem,
				line-height: math.div(26, 18),
			),
		),
	),
	base: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(18),
		font-weight: $weightRegular,
		line-height: math.div(26, 18),
		variants: (
			$smallLayout: (
				font-size: pxToRem(22),
				letter-spacing: 0.05rem,
				line-height: math.div(30, 22),
			),
		),
	),
	baseItalic: (
		font-family: $fontFamilyMain,
		font-style: italic,
		font-weight: $weightRegular,
		variants: (
			$smallLayout: (
				font-size: pxToRem(22),
				letter-spacing: 0.1rem,
				line-height: math.div(30, 22),
			),
		),
	),
	baseMedium: (
		font-family: $fontFamilyAlt,
		font-weight: $weightMedium,
		font-size: pxToRem(18),
		letter-spacing: 0.023rem,
		line-height: math.div(26, 18),
		variants: (
			$smallLayout: (
				font-size: pxToRem(22),
				letter-spacing: 0.028rem,
				line-height: math.div(30, 22),
			),
		),
	),
	baseBold: (
		font-family: $fontFamilyAlt,
		font-weight: $weightBold,
		variants: (
			$smallLayout: (
				font-size: pxToRem(21),
				letter-spacing: 0.05rem,
				line-height: math.div(30, 21),
			),
		),
	),
	baseSemiBold: (
		font-family: $fontFamilyAlt,
		font-weight: $weightSemiBold,
		font-size: pxToRem(18),
		letter-spacing: 0.023rem,
		line-height: math.div(26, 18),
		variants: (
			$smallLayout: (
				font-size: pxToRem(22),
				letter-spacing: 0.028rem,
				line-height: math.div(30, 22),
			),
		),
	),
	titleExtraLight: (
		font-family: $fontFamilyMain,
		font-style: italic,
		font-weight: $weightExtraLight,
		font-size: pxToRem(22),
		letter-spacing: 0.028rem,
		line-height: math.div(28, 22),
		variants: (
			$smallLayout: (
				font-size: pxToRem(36),
				letter-spacing: 0.045rem,
				line-height: math.div(44, 36),
			),
		),
	),
	titleMedium: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(22),
		font-weight: $weightMedium,
		letter-spacing: 0.028rem,
		line-height: math.div(28, 22),
		variants: (
			$smallLayout: (
				font-size: pxToRem(35),
				letter-spacing: 0.044rem,
				line-height: math.div(44, 35),
			),
		),
	),
	highlightTitle: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(50),
		font-weight: $weightLight,
		variants: (
			$smallLayout: (
				font-size: pxToRem(100),
				line-height: 100 * 0.01,
			),
			$largeLayout: (
				font-size: pxToRem(130),
				line-height: math.div(130, 130),
			),
		),
	),
	headerServiceMenu: (
		// NOTE: possibly obsolete with menu redesign
		font-family: $fontFamilyAlt,
		font-size: pxToRem(16),
		font-weight: $weightMedium,
		line-height: 1.4,
		letter-spacing: pxToRem(0.4),
	),
	form: (
		// NOTE: could be replaced with new font values, wait for design
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		font-weight: $weightRegular,
		letter-spacing: pxToRem(0.5),
		line-height: 1.29,
		variants: (
			">xSmall": (
				font-size: pxToRem(18),
				line-height: 1.33,
			),
		),
	),
	formSubmit: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		font-weight: $weightSemiBold,
		letter-spacing: pxToRem(1),
	),
	footerMenuAdditionalLinks: (
		font-family: $fontFamilyAlt,
		font-weight: $weightMedium,
		font-size: pxToRem(12),
		letter-spacing: pxToRem(0.86),
		line-height: math.div(16, 12),
		variants: (
			$smallLayout: (
				font-size: pxToRem(14),
				letter-spacing: pxToRem(1),
				line-height: math.div(22, 14),
			),
		),
	),
	footerMenu2ndLevel: (
		font-family: $fontFamilyMain,
		font-style: italic,
		font-weight: $weightRegular,
		font-size: pxToRem(14),
		letter-spacing: pxToRem(0.39),
		line-height: math.div(18, 14),
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				letter-spacing: pxToRem(0.5),
				line-height: math.div(26, 18),
			),
		),
	),
	slideCategory: (
		// NOTE: could be replaced with new font values, wait for design
		font-family: $fontFamilyAlt,
		font-size: pxToRem(16),
		line-height: 1.6,
		font-weight: $weightMedium,
		letter-spacing: pxToRem(4),
		variants: (
			$smallLayout: (
				font-size: pxToRem(24),
				letter-spacing: pxToRem(6),
			),
		),
	),
	slideTitle: (
		// NOTE: could be replaced with new font values, wait for design
		font-family: $fontFamilyAlt,
		font-size: pxToRem(24),
		line-height: 1.2,
		font-weight: $weightMedium,
		letter-spacing: pxToRem(0.5),
		variants: (
			$smallLayout: (
				font-size: pxToRem(40),
			),
		),
	),
	slideSubtitle: (
		// NOTE: could be replaced with new font values, wait for design
		font-family: $fontFamilyAlt,
		font-size: pxToRem(11),
		line-height: 1.6,
		font-weight: $weightMedium,
		letter-spacing: pxToRem(1.2),
		variants: (
			$smallLayout: (
				font-size: pxToRem(16),
			),
		),
	),
	slideAltTitle: (
		// NOTE: could be replaced with new font values, wait for design
		font-family: $fontFamilyAlt,
		font-size: pxToRem(16),
		font-weight: normal,
		line-height: 1.6,
		variants: (
			$smallLayout: (
				font-size: pxToRem(28),
			),
		),
	),
	imageCredit: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		letter-spacing: pxToRem(0.39),
		line-height: math.div(18, 14),
		variants: (
			$smallLayout: (
				font-size: pxToRem(18),
				line-height: math.div(26, 18),
				letter-spacing: pxToRem(0.5),
			),
		),
	),
	newBase: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(20, 14),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18),
			),
		),
	),
	newBaseSecond: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(20, 14),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18),
			),
		),
	),
	newBaseSmall: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(20, 14),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(15),
				line-height: math.div(20, 15),
			),
		),
	),
	newCategory: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(12),
		font-weight: $weightRegular,
		letter-spacing: 0.7em,
		line-height: math.div(14, 12),
		text-transform: uppercase,
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(15),
				line-height: math.div(17, 15),
			),
		),
	),
	newHeadingOne: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(34),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(40, 34),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(44),
				line-height: math.div(50, 44),
			),
		),
	),
	newHeadingOneV2: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(28),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(34, 28),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(60),
				line-height: math.div(65, 60),
			),
		),
	),
	newHeadingSecond: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(28),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(34, 28),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(38),
				line-height: math.div(48, 38),
			),
		),
	),
	newHeadingSecondV2: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(23),
		font-weight: $weightRegular,
		line-height: math.div(28, 23),
		letter-spacing: inherit,
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(39),
				line-height: math.div(48, 39),
			),
		),
	),
	newHeadingSecondV3: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(40),
		font-weight: $weightLight,
		letter-spacing: -0.02em,
		line-height: 40 * 0.025,
		text-transform: uppercase,
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(60),
				line-height: math.div(60, 60),
			),
		),
	),
	newHeadingSecondV4: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(23),
		font-weight: $weightRegular,
		line-height: math.div(28, 23),
		letter-spacing: 0.028em,
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(39),
				line-height: math.div(48, 39),
				letter-spacing: 0.026em,
			),
		),
	),
	newHeadingThird: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(26),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(34, 26),
	),
	newHeadingFifth: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(22),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(28, 22),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18),
			),
		),
	),
	newHeadingFifthV2: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(20, 14),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18),
			),
		),
	),
	newHeadingSixth: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(20, 14),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(15),
				line-height: math.div(20, 15),
			),
		),
	),
	newLinkSlider: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(20, 14),
		variants: (
			$largeNewLayout: (
				letter-spacing: -0.02em,
				font-size: pxToRem(15),
				line-height: math.div(20, 15),
			),
		),
	),
	newBaseText: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(18),
		font-weight: $weightRegular,
		line-height: math.div(26, 18),
		letter-spacing: normal,
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(22),
				line-height: math.div(34, 22),
			),
		),
	),
	newBoldBaseText: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(17),
		font-weight: $weightSemiBold,
		line-height: math.div(26, 17),
		letter-spacing: -0.02em,
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(21),
				line-height: math.div(34, 21),
			),
		),
	),
	newTitleSlider: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(22),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(28, 22),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(26),
				letter-spacing: -0.04em,
				line-height: math.div(34, 26),
			),
		),
	),
	newProgramLink: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(12),
		font-weight: $weightRegular,
		letter-spacing: 0.3em,
		line-height: math.div(14, 12),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(15),
				line-height: math.div(17, 15),
			),
		),
	),
	newSubTitle: (
		font-family: $fontFamilyMain,
		font-size: pxToRem(23),
		font-weight: $weightLight,
		font-style: italic,
		line-height: math.div(28, 23),
		letter-spacing: normal,
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(39),
				line-height: math.div(48, 39),
			),
		),
	),
	newSectionTitle: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(12),
		font-weight: $weightRegular,
		letter-spacing: 0.7em,
		line-height: math.div(14, 12),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(15),
				line-height: math.div(17, 15),
			),
		),
	),
	headerLinks: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(12),
		font-weight: $weightRegular,
		letter-spacing: 0.3em,
		line-height: math.div(14, 12),
		text-transform: uppercase,
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(15),
				line-height: math.div(20, 15),
			),
		),
	),
	newProfileTitle: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(45),
		font-weight: $weightMedium,
		letter-spacing: -0.02em,
		line-height: math.div(40, 45),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(120),
				line-height: math.div(100, 120),
			),
		),
	),
	newMainNavFirstLevel: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(22),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(28, 22),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(38),
				line-height: math.div(48, 38),
			),
		),
	),
	newMainNavSecondLevel: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(17),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(26, 17),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18),
			),
		),
	),
	newExtraNavLink: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(17),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(26, 17),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(21),
				line-height: math.div(24, 21),
			),
		),
	),
	newAltTextEditor: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(11),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(16, 11),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(15),
				line-height: math.div(20, 15),
			),
		),
	),
	newTitileEditor: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(28),
		font-weight: $weightMedium,
		letter-spacing: -0.02em,
		line-height: math.div(34, 28),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(60),
				line-height: math.div(65, 60),
			),
		),
	),
	newNewsletterTicker: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(28),
		font-weight: $weightRegular,
		letter-spacing: 0.7em,
		line-height: math.div(34, 28),
		text-transform: uppercase,
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(38),
				line-height: math.div(48, 38),
			),
		),
	),
	newSocialMediaLink: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(20, 14),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(15),
				line-height: math.div(20, 15),
			),
		),
	),
	newTitleRichLink: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(17),
		font-weight: $weightSemiBold,
		letter-spacing: -0.02em,
		line-height: math.div(26, 17),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(21),
				line-height: math.div(34, 21),
			),
		),
	),
	newTitleAccordion: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(22),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(28, 22),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(38),
				line-height: math.div(48, 38),
			),
		),
	),
	newSubTilteAccordion: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(14),
		font-weight: $weightSemiBold,
		letter-spacing: -0.02em,
		line-height: math.div(20, 14),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(18),
				line-height: math.div(24, 18),
			),
		),
	),
	profileDescription: (
		font-family: $fontFamilyAlt,
		font-size: pxToRem(17),
		font-weight: $weightRegular,
		letter-spacing: -0.02em,
		line-height: math.div(26, 17),
		variants: (
			$largeNewLayout: (
				font-size: pxToRem(21),
				line-height: math.div(34, 21),
			),
		),
	),
);
