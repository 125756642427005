.newForm {
	&__fields {
		@include staticColumns();
		flex-direction: column;
		flex-wrap: wrap;
		position: relative;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			align-items: flex-end;
			flex-direction: row;
		}
	}

	input:not([type=checkbox]),
	label,
	textarea {
		@include font(newBaseSmall);
	}
}
