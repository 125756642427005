.newMainTeaser {

	&__link {
		float: left;
	}

	&__linkLine {
		.newMainTeaser__linksWrapper &:last-child {
			display: none;
		}
	}

	&__linkText {
		//@include new-alternative-link();

		display: inline;
		padding: {
			left: $baseHalf;
			right: $baseHalf;
		}
		position: relative;

		color: var(--highlight-color);
		text-decoration: none;

		border-bottom: {
			color: transparent;
			style: solid;
			width: 1px;
		}

		@include onHover() {
			border-bottom-color: var(--highlight-color);
		}

		.newMainTeaser__linksWrapper &:first-child {
			padding-left: 0;
		}
	}

	&__linksWrapper {
		@include font(newBaseSmall);
		color: var(--highlight-color);
	}

	&__category {
		@include font(newCategory);
		letter-spacing: 0.765em;
		margin-bottom: 0;
		overflow-wrap: break-word;
		position: absolute;
		text-align: left;
		top: 0;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $base1;
			padding-right: $categoryMediumPadding;
			position: inherit;
			text-align: right;
			top: auto;
			width: staticColumnSize(4, 10, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin: {
				bottom: $vwL50;
				top: 0;
			}
			padding-right: $vwL20;
			text-align: left;
			width: 100%;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base5;
		}

		.newMainTeaser--secondVersion & {
			text-align: left;

			//>=768px
			@include media($mediumNewLayout) {
				text-align: right;
				width: staticColumnSize(3, 8, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				left: 0;
				margin: {
					bottom: 0;
					top: 0;
				}
				position: absolute;
				top: $categoryTopMargin;
				width: staticColumnSize(3, 11, $newGutterLarge);
			}
		}
	}

	&__categoryLink {
		@include new-base-link();

		.newMainTeaser--secondVersion & {

			//>=1280px
			@include media($largeNewLayout) {
				padding-right: $base1;
			}
		}
	}

	&__dates {
		@include font(newCategory);
		@include media($mediumNewLayout) {
			margin-top: $base1;
		}
		time + time {
			&:before {
				content: '–';
				color: var(--highlight-color);
			}
		}
		@include responsiveProps(
			$margin-bottom: (5px, 7px, 4px, 5px),
		);
	}

	&__timeAndPlace {
		@include font(newBase);
		> span + span {
			&:before {
				content: ' | ';
				color: var(--highlight-color);
			}
		}
		@include responsiveProps(
			$margin-bottom: (10px, 10px, 7px, 9px),
		);
	}

	&__content {
		@include staticColumns();
		flex-direction: column;
		margin-left: 0;
		padding-top: $vwS20;
		position: relative;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			padding-top: 0;
			width: staticColumnSize(11, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-left: staticColumnPush(1, 12, $newGutterSmall);
			flex-direction: row;
			width: staticColumnSize(10, 12, $newGutterLarge);
		}

		.newMainTeaser--secondVersion & {

			//>=768px
			@include media($mediumNewLayout) {
				margin-left: staticColumnPush(1, 12, $newGutterSmall);
				width: staticColumnSize(8, 12, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				justify-content: flex-end;
				margin-left: 0;
				position: relative;
				width: staticColumnSize(11, 12, $newGutterLarge);
			}
		}
	}

	&__contentWrapper {
		@include staticColumns();
		flex-direction: column;
		margin-top: $vwS10;
		padding-left: 0;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			flex-direction: row;
			margin-top: $vwM30;
			width: staticColumnSize(10, 11, $newGutterSmall);
		}

		//>=1280px
		@include media($largeNewLayout) {
			display: block;
			justify-content: flex-start;
			margin-top: 0;
			padding-left: $vwL30;
			width: staticColumnSize(4, 10, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding-left: $largeHorizontalSpacing;
		}

		.newMainTeaser--secondVersion & {

			//>=768px
			@include media($mediumNewLayout) {
				width: 100%;
			}
			//>=1280px
			@include media($largeNewLayout) {
				padding-left: 0;
				width: staticColumnSize(4, 11, $newGutterLarge);
			}
		}
	}

	&__description {
		@include font(newBase);
		margin-bottom: $vwS10;

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL30;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base3;
		}
	}

	&__imageCropContainer {
		position: relative;
		width: 100%;
		//overflow: hidden;

		&::after {
			display: block;
			width: 100%;
			content: '';
			padding-bottom: 75%; // 4/3 ratio
		}

		.newMainTeaser--secondVersion & {
			&::after {
				display: block;
				width: 100%;
				content: '';
				padding-bottom: 100%; // 1/1 ratio
			}
			//>=768px
			@include media($mediumNewLayout) {
				&::after {
					content: none;
				}
			}
		}
	}

	&__image {
		overflow: hidden;

		&--croppedImage {
			height: 100%;
			position: absolute;
			width: 100%;
			top: 0;
			left: 0;
			bottom: 0;
			margin: auto;

			.newMainTeaser--secondVersion & {
				@include media($mediumNewLayout) {
					position: relative;
					display: block;
				}
			}
		}
	}

	&__img {
		@include ease(transform, $duration3, $easeOutQuad);
		@include objectFit(cover);
		height: 100%;
		max-width: 100%;
		position: relative;
		@include onHover('.newMainTeaser__mediaLink', ' &') {
			transform: scale($scaleImg);
		}
	}

	&__list {
		display: none;
		list-style-type: square;
		margin-bottom: $vwS10;

		//>=768px
		@include media($mediumNewLayout) {
			display: block;
			margin-bottom: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL20;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base2;
		}
	}

	&__listItem {
		@include font(newBaseSmall);
		background-color: var(--background-color);
		margin-bottom: 0;
		position: relative;

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: $vwM05;
			margin-left: $mediumHorizontalSpacing;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL10;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base1;
		}

		&::before {
			background-color: var(--color);
			bottom: $base1;
			content: '';
			height: 1px;
			position: absolute;
			right: 0;
			width: 100%;
			z-index: 1;
		}
	}

	&__listItemText {
		background-color: var(--background-color);
		padding-right: $vwS10;
		position: relative;
		z-index: 2;

		//>=768px
		@include media($mediumNewLayout) {
			padding-right: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-right: $vwL10;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding-right: $base1;
		}
	}

	&__mediaLink {
		display: block;
	}

	&__mediaWrapper {
		margin-left: 0;
		overflow: hidden;
		position: relative;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin-left: staticColumnPush(4, 11, $newGutterSmall);
			width: staticColumnSize(7, 11, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-left: 0;
			width: staticColumnSize(6, 10, $newGutterLarge);
		}

		.newMainTeaser--secondVersion & {

			//>=768px
			@include media($mediumNewLayout) {
				margin-left: staticColumnPush(3, 8, $newGutterSmall);
				width: staticColumnSize(5, 8, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				margin: {
					left: 0;
					right: $newGutterLarge;
				}
				width: staticColumnSize(4, 11, $newGutterLarge);
			}
		}
	}

	&__textWrapper {
		padding-left: 0;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			padding-left: 0;
			width: staticColumnSize(6, 10, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: 100%;
		}

		.newMainTeaser--secondVersion & {

			//>=768px
			@include media($mediumNewLayout) {
				padding-left: 0;
				width: staticColumnSize(5, 8, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				padding-left: $vwL30;
				width: 100%;
			}
			//>=1680px
			@include media($xLargeNewLayout) {
				padding-left: $base3;
			}
		}
	}

	&__title {
		@include font(newHeadingSecond);
		margin-bottom: $vwS10;

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL30;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-bottom: $base3;
		}
	}

	&__titleLink {
		@include new-title-link();
	}

	&__video {
		position: relative;
	}
}
