@use "sass:math";


.newTextBlock {
	&__category {
		@include font(newCategory);
		margin: {
			bottom: $vwS10;
			top: 0;
		}
		padding-right: 0;
		text-align: left;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin: {
				bottom: 0;
				top: $newCategoryTopMargin;
			}
			padding-right: $categoryMediumPadding;
			text-align: right;
			width: staticColumnSize(4, 11, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-right: $vwL20;
			width: staticColumnSize(3, 10, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			padding-right: $base2;
			width: staticColumnSize(3, 9, $newGutterLarge);
		}

		&--top {
			//>=768px
			@include media($mediumNewLayout) {
				margin-top: calc($newCategoryTopMargin + ($vwM15 - $newCategoryTopMargin));
			}

			//>=1280px
			@include media($largeNewLayout) {
				margin-top: calc($newCategoryTopMargin + ($vwL15 - $newCategoryTopMargin));
			}

			//>=1440px
			@include media($xLarge2vNewLayout) {
				margin-top: calc($newCategoryTopMargin + ($vwL20 - $newCategoryTopMargin));
			}

			//>=1680px
			@include media($xLargeNewLayout) {
				margin-top: calc($newCategoryTopMargin + ($base2 - $newCategoryTopMargin));
			}
		}
	}

	&__content {
		@include staticColumns();
		flex-direction: column;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			flex-direction: row;
			align-items: baseline;
			width: staticColumnSize(11, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(10, 12, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			width: staticColumnSize(9, 12, $newGutterLarge);
		}

		//>=768px
		&--aliginUnset {
			align-items: unset;
		}
	}

	&__contentWrapper {
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(7, 11, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(7, 10, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			width: staticColumnSize(6, 9, $newGutterLarge);
		}
	}

	&__listItem {
		border-bottom: {
			style: solid;
			width: 1px;
		}
		padding: {
			bottom: $vwS20;
			top: $vwS20;
		}

		//>=768px
		@include media($mediumNewLayout) {
			padding: {
				bottom: $vwM20;
				top: $vwM20;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				bottom: $vwL30;
				top: $vwL30;
			}
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			padding: {
				bottom: $base3;
				top: $base3;
			}
		}

		&:first-child {
			padding-top: 0;
		}
	}

	&__listWrapper {
		width: 100%;
	}

	&__textWrapper + &__richLinkWrapper {
		margin-top: $vwS20;
		border-top: 1px solid #636262;
		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM20;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL30;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base3;
		}

		.block--darkColorVersion &,
		.block--vividColorVersion & {
			border-top: 1px solid white;
		}
	}

	&__wrapperMedia {
		@include aspectRatio(math.div(4, 3));
		overflow: hidden;
		position: relative;
		margin-bottom: $vwS;
		height: fit-content;
		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: $base0;
			width: staticColumnSize(2, 7, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(2, 7, $newGutterLarge);
		}
		//1440px
		@include media($xLarge2vNewLayout) {
			width: staticColumnSize(2, 6, $newGutterLarge);
		}

		&--nospace {
			margin-bottom: $base0;
		}
	}

	&__richLinkWrapper {
		width: 100%;
		border-bottom: 1px solid $colorBgSecondAlternative;

		.block--darkColorVersion &,
		.block--vividColorVersion & {
			border-bottom: 1px solid $colorBgBase;
		}

		padding: {
			top: $vwS15;
			bottom: $vwS15;
		}

		&:first-child {
			border-top: 1px solid $colorBgSecondAlternative;

			.block--darkColorVersion &,
			.block--vividColorVersion & {
				border-top: 1px solid white;
			}
		}

		//>=768px
		@include media($mediumNewLayout) {
			@include staticColumns();
			padding: {
				top: $vwM15;
				bottom: $vwM15;
			}
		}

		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				top: $vwL15;
				bottom: $vwL15;
			}
		}

		//>=1440px
		@include media($xLarge2vNewLayout) {
			padding: {
				top: $vwL20;
				bottom: $vwL20;
			}
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			padding: {
				top: $base2;
				bottom: $base2;
			}
		}
	}

	&__richLinkWrapper + &__textWrapper {
		margin-top: $vwS20;
		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM20;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL30;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base3;
		}
	}

	&__shortTitle {
		@include font(newTitleRichLink);
		color: $colorHighlight;

		.block--vividColorVersion & {
			color: $colorBgBase;
		}
	}

	&__shortTitleLink {
		@include ease(color border-bottom-color, $hoverDuration);
		@include new-block-link();
		color: $colorHighlight;
		@include onHover() {
			color: $colorHighlight;
			border-bottom-color: $colorBase;
		}

		.block--vividColorVersion & {
			color: $colorBgBase;
			border-bottom: 1px solid $colorBgBase;
			@include onHover() {
				color: $colorBase;
				border-bottom-color: $colorBase;
			}
		}

		.block--darkColorVersion & {
			@include onHover() {
				border-bottom-color: $colorBgBase;
			}
		}
	}

	&__descriptionRichLink {
		@include font(newHeadingFifthV2);
		margin-top: $vwS05;
		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM05;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL15;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base1 + $baseHalf;
		}

		&--nospace {
			margin-top: $base0;
		}
	}

	&__image {
		width: 100%;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
	}

	&__heading {
		@include font(newCategory);
		margin-bottom: $vwS05;
		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: $vwM05;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL;
		}
		//1440px
		@include media($xLarge2vNewLayout) {
			margin-bottom: $base1;
		}
	}

	&__richLinkContent {
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(5, 7, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(5, 7, $newGutterLarge);
		}
		//1440px
		@include media($xLarge2vNewLayout) {
			width: staticColumnSize(4, 6, $newGutterLarge);
		}
	}

	&__img {
		display: block;
		@include ease(transform, $duration3, $easeOutQuad);
		@include objectFit(cover);
		min-height: 100%;

		@include onHover('.newTextBlock__image', ' &') {
			transform: scale($scaleImg);
		}
	}
}
