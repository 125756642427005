.nft{
    width: 100%;
    text-align: right;
    &-button{
        @include font(newCategory);
        color: $colorBgBase;
        background: $colorHighlight;
        padding: $base1+0.5 $base3;
        cursor: pointer;
        width: 100%;
        border: 1px solid $colorHighlight;
        transition: 0.6s ease-in;
        &:hover{
            border: 1px solid $colorHighlight;
            background: transparent;
            color: $colorHighlight;
        }
        @include media($smallLayout){
            width: auto;
        }

        &.js-connected{
            border: 1px solid $lightColorBg;
            color: $colorBase;
            background: $lightColorBg;
            cursor: default;
            &:hover{
                background: transparent;
            }
        }

        &.js-blink{
            animation: blinkingBackground 1s 2s;
        }
        @keyframes blinkingBackground{
            0%		{ background-color: $colorHighlight;}
            25%		{ background-color: transparent;}
            50%		{ background-color: $colorHighlight;}
            75%		{ background-color: transparent;}
            100%	{ background-color: $colorHighlight;}
        }
    }
}