.scrollIndicator {
	left: 0;
	pointer-events: none;
	position: fixed;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
	z-index: 99;


	&__button {
		cursor: pointer;
		padding: 0 $base*0.5 $base*0.5 $base*0.5;
		color: currentColor;
		display: block;
		pointer-events: none;

		@include media($smallLayout) {
			pointer-events: all;
		}

		&::after {
			// @include ease(background-color, $hoverDuration);
			background-color: $colorBgBase;
			border-radius: 50%;
			border: solid currentColor 1px;
			content: '';
			display: block;
			height: $base * 0.6;
			width: $base * 0.6;

			@include media($smallLayout) {
				border-width: 2px;
				height: $base;
				width: $base;
			}
		}

		&.js-active::after {
			background-color: currentColor;
			border-color: $colorInverted;
			height: $base * (0.6 + 0.2);
			margin-bottom: - $base * 0.2;
			transform: translate(#{$base * 0.1}, #{$base * -0.1});
			width: $base * (0.6 + 0.2);

			@include media($smallLayout) {
				height: $base * 1.4;
				margin-bottom: - $base * 0.4;
				transform: translate(#{$base * 0.2}, #{$base * -0.2});
				width: $base * 1.4;
			}
		}

		// @include onHover('&', '::after') {
		// 	background-color: $colorHighlight;
		// }
	}

	&__list {
		@include app-container-wide();
		align-items: flex-end;
		display: flex;
		flex-direction: column;
		padding-right: $base * 0.25;

		@include media($smallLayout) {
			padding-right: $containerPaddingSmall * 0.5 - $menuTogglerSizeSmall * 0.5 + $base * 0.5;
		}
		@include media($mediumLayout) {
			padding-right: $containerPaddingMedium;
			transform: translateX(#{$containerPaddingMedium*0.5 + $menuTogglerSizeSmall*0.5 - $base*0.5});
		}
		@include media($largeLayout) {
			padding-right: $containerPaddingLarge;
			transform: translateX(#{$containerPaddingLarge*0.5 + $menuTogglerSizeLarge*0.5 - $base*0.5});
		}
	}

	&__listElement {
		display: block;
	}
}
