.team {
	$teamSpace: $base5;
	// flex-grow: 1;

	.block--team.block--layoutThreeColumns &,
	.block--team.block--layoutTwoColumns & {
		@include dynamicColumns($gutterSmall, $base3);

		@include media($smallLayout) {
			@include dynamicColumns($gutterMedium, $base3);
		}

		@include media($largeLayout) {
			@include dynamicColumns($gutterLarge, $base3);
		}
	}

	&__additonalText {
		margin-top: $base2;

		@include media($mediumLayout) {
			column-gap: $base5;
			column-width: 0;
			column-count: 2;
			margin-top: $base5;
			word-wrap: break-word;
		}
	}


	&__close {
		@include ease(color, $hoverDuration);
		color: currentColor;
		position: absolute;
		top: 0;
		right: 0;

		@include onHover() {
			color: $colorHighlight;
		}

		[data-type="svg"] {
			height: $base2;
		}
	}



	&__member {
		position: relative;
		text-align: center;

		.block--team.block--layoutTwoColumns & {
			width: dynamicColumnSize(1, 2, $gutterSmall);

			@include media($smallLayout) {
				width: dynamicColumnSize(6, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				width: dynamicColumnSize(6, 12, $gutterLarge);
			}
		}


		.block--team.block--layoutThreeColumns & {
			width: dynamicColumnSize(1, 2, $gutterSmall);

			@include media($smallLayout) {
				width: dynamicColumnSize(6, 12, $gutterMedium);
			}

			@include media($mediumLayout) {
				width: dynamicColumnSize(4, 12, $gutterMedium);
			}

			@include media($largeLayout) {
				width: dynamicColumnSize(4, 12, $gutterLarge);
			}
		}

		&.js-selected {
			margin-left: -$containerPaddingSmall;
			margin-right: -$containerPaddingSmall;

			@include media($smallLayout) {
				margin-left: $teamSpace;
				margin-right: 0;
				z-index: 2;
			}
		}
	}


	&__role {
		@include font(baseSmallAlternate);
	}


	&__title {
		@include font(baseSmallSemiBold);
		color: $colorHighlight;
		margin: $base 0 0 0;

		@include media($smallLayout) {
			margin-top: $base2;
		}

		.team__toggler & {
			@include ease(color, $hoverDuration);
		}

		@include onHover('.team__toggler', ' &') {
			color: $colorHighlightLight;
		}
	}


	&__toggler {
		color: currentColor;
		text-decoration: none;

		.team__member.js-selected & {
			display: none;

			@include media($smallLayout) {
				display: block;
			}
		}
	}
}
