.anchor {
	display: block;
	position: relative;
	top: -$firstBlockTopSpacingSmall;
	visibility: hidden;

	@include media($mediumLayout) {
		top: -$firstBlockTopSpacingLarge;
	}
}
