.newVideo {
	&__caption {
		@include font(newAltTextEditor);
		margin-top: $baseHalf;
		width: 100%;

		//>=1280
		@include media($largeNewLayout) {
			width: staticColumnSize(6, 7, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			width: 100%;
		}

		> * {
			display: inline-block;
		}
	}

	&__content {
		@include staticColumns();
		flex-direction: column;
		position: relative;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			flex-direction: row;
			width: staticColumnSize(11, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(10, 12, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			width: staticColumnSize(9, 12, $newGutterLarge);
		}
	}

	&__contentWrapper {
		@include staticColumns();
		flex-direction: column;
		margin-left: 0;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin-left: staticColumnPush(4, 11, $newGutterSmall);
			width: staticColumnSize(7, 11, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-left: staticColumnPush(3, 10, $newGutterLarge);
			width: staticColumnSize(7, 10, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			margin-left: staticColumnPush(3, 9, $newGutterLarge);
			width: staticColumnSize(6, 9, $newGutterLarge);
		}
	}

	&__headline {
		@include font(newCategory);
		padding: {
			bottom: $vwS10;
			right: 0;
			top: 0;
		}
		position: relative;
		text-align: left;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			padding: {
				bottom: 0;
				right: $vwM05;
			}
			position: absolute;
			text-align: right;
			width: staticColumnSize(4, 11, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				right: $vwL20;
				top: $vwL10;
			}
			width: staticColumnSize(3, 10, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			padding: {
				right: $base2;
				top: 0;
			}
			width: staticColumnSize(3, 9, $newGutterLarge);
		}
	}

	&__link {
		@include new-caption-link();

		&::before {
			color: $colorBase;
			content: "|";
			display: inline-block;
			height: 100%;
			margin: {
				left: 0;
				right: $newLinkMarginRight;
			}
			width: 1px;

			.block--darkColorVersion &,
			.block--vividColorVersion & {
				color: $colorBgBase;
			}
		}
	}

	&__videoWrapper {
		position: relative;
		overflow: hidden;
		width: 100%;
	}
}
