@mixin app-link() {
	@include ease(color, $hoverDuration);
	color: currentColor;
	font-weight: $weightSemiBold;
	text-decoration: none;

	@include onHover() {
		color: $colorHighlight;
	}

	.header--vividColorVersion & {
		@include onHover() {
			color: $colorBase;
		}
	}
}

@mixin new-base-link() {
	@include ease(color, $hoverDuration);
	color: currentColor;
	text-decoration: none;

	@include onHover() {
		color: $colorHighlight;
	}
}

@mixin new-block-link() {
	@include ease(color border-bottom-color, $hoverDuration);
	color: $colorHighlight;
	text-decoration: none;
	border-bottom: {
		color: $colorHighlight;
		style: solid;
		width: 1px;
	}
}

@mixin new-alternative-link() {
	@include ease(color, $hoverDuration);
	color: var(--highlight-color);
	text-decoration: none;
	@include onHover() {
		text-decoration: underline;
		text-underline-offset: 6px;
		text-decoration-color: var(--color);
	}
}

@mixin new-caption-link() {
	@include ease(color, $hoverDuration);
	color: $colorHighlight;
	text-decoration: underline;

	@include onHover() {
		text-decoration: none;
	}

	.block--vividColorVersion & {
		color: $colorBase;
	}
}

@mixin _new-title-link() {
	@include ease(color background-color, $hoverDuration);
	color: currentColor;
	text-decoration: none;

	@include onHover() {
		box-shadow: 0 -1.3em 0 0 $colorHighlight inset, -0.1em 0.16rem 0 0 $colorHighlight, -0.1em 0 0 0 $colorHighlight, 0.1em 0.16rem 0 0 $colorHighlight, 0.1em 0 0 0 $colorHighlight, 0 0 0 0 $colorHighlight, -0.1em -0.1em 0 0 $colorHighlight, -0.1em -0.1em 0 0 $colorHighlight, 0.1em -0.1em 0 0 $colorHighlight;
		color: $colorBgBase;
	}
}


@mixin new-title-link() {
	@include ease(color box-shadow background, $hoverDuration);
	color: currentColor;
	text-decoration: none;
	background: rgba(255, 255, 255, 0);
	box-shadow: 0.1em 0 0 rgba(255, 255, 255, 0), -0.1em 0 0 rgba(255, 255, 255, 0);

	@include onHover() {
		background: $colorHighlight;
		box-shadow: 0.1em 0 0 $colorHighlight, -0.1em 0 0 $colorHighlight;
		box-decoration-break: clone;
		color: $colorBgBase;
	}
}

@mixin new-large-title-link() {
	@include ease(color box-shadow background, $hoverDuration);
	color: currentColor;
	text-decoration: none;
	background: rgba(255, 255, 255, 0);
	box-shadow: 0.1em 0 0 rgba(255, 255, 255, 0), -0.1em 0 0 rgba(255, 255, 255, 0);

	@include onHover() {
		background: $colorHighlight;
		box-shadow: 0.1em 0 0 $colorHighlight, -0.1em 0 0 $colorHighlight;
		box-decoration-break: clone;
		color: $colorBgBase;
	}
}

@mixin new-toggler-link() {
	@include ease(color background-color color, $durationFast, $easeLinear);
	background-color: $newColorBgMenuToggler;

	@include onHover() {
		background-color: $colorHighlight;
		color: $colorBgBase;
	}

	.body.js-scrolledDown & {
		background-color: $colorBase;
		color: $colorBgBase;

		@include onHover() {
			background-color: $colorHighlight;
			color: $colorBgBase;
		}
	}

	.header--darkColorVersion:not(.js-coveredByNewMenu) &,
	.header--lightColorVersion:not(.js-coveredByNewMenu) & {
		color: $colorBgBase;

		@include onHover() {
			background-color: $colorHighlight;
			color: $colorBgBase;
		}
	}

	.header--darkColorVersion:not(.js-coveredByNewMenu) & {
		background-color: $colorBgSecondAlternative;

		.body.js-scrolledDown & {
			background-color: $colorBase;
		}
	}

	.header--lightColorVersion:not(.js-coveredByNewMenu) & {
		background-color: $colorBase;
	}

	.header--vividColorVersion:not(.js-coveredByNewMenu) & {
		background-color: $colorBgBase;
		color: $colorBase;

		@include onHover() {
			background-color: $colorBase;
			color: $colorBgBase;
		}
	}
}

@mixin new-newsletter-main-link() {
	@include ease(color, $hoverDuration);
	border-bottom: 1px solid $colorBgBase;
	color: $colorBgBase;
	text-decoration: none;

	@include onHover() {
		border-bottom-color: $colorBase;
		color: $colorBase;
	}
}

@mixin new-newsletter-media-link() {
	@include ease(color, $hoverDuration);
	color: $colorBgBase;
	text-decoration: none;

	@include onHover() {
		text-decoration: underline;
	}
}
