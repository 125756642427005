
$duration: 0.1s;
$duration1: $duration;
$duration2: $duration * 2;
$duration3: $duration * 3;
$duration4: $duration * 4;
$duration5: $duration * 5;
$duration6: $duration * 6;
$duration7: $duration * 7;
$duration8: $duration * 8;
$duration9: $duration * 9;
$duration10: $duration * 10;
$duration05: $duration * 0.5;

$easeLinear: linear;
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);

$durationBase: $duration4;
$durationLong: $duration8;
$durationFast: $duration2;
$durationVeryFast: $duration;

$durationDelayBetweenElements: $duration * 0.2;

$easeBase: $easeOutQuad;

$teaserBgOpacity: 0.5;

$opacityInputDisabled: 0.2;
$hoverBgOpacity: 0.5;

$slideshowFadeDuration: $duration4;
$slideshowSlideDuration: $duration4;

$hoverDuration: $duration2;
$teaserUnderlineDuration: $duration4;

$teamDuration: $duration2;

$footerTabDuration: $duration6;
$fadeDuration: $duration4;
$spinningIconDuration: $duration7;


$jigsawHover: 0.8;
$mainMenuFadeDuration: $duration4;

$menuTransformDuration: $duration;
$menuRotationDuration: $duration5;
$menuFadeDuration: $duration3;
$menuTransformTiming: cubic-bezier(0.73, 0.74, 0.47, 0.93);

$newsletterAnchorScaleDuration: 1s;
$newsletterAnchorFadeDuration: 0.5s;
$newsletterAnchorExpandDuration: 1s;
