@use 'sass:math';

.newProfileHero {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column-reverse;

	//768
	@include media($mediumNewLayout) {
		@include staticColumns();
		width: staticColumnSize(11, 12, $newGutterSmall);
		margin-left: staticColumnPush(1, 12, $newGutterSmall);
	}
	//>=1280px
	@include media($largeNewLayout) {
		width: staticColumnSize(11, 12, $newGutterLarge);
		margin-left: staticColumnPush(1, 12, $newGutterLarge);
	}
	//>=1680px
	@include media($xLargeNewLayout) {
		width: staticColumnSize(10, 12, $newGutterLarge);
		margin-left: staticColumnPush(1, 12, $newGutterLarge);
	}

	&__altText {
		@include ease(opacity visibility, $duration2);
		@include font(newLinkSlider);
		margin-top: $vwM05;
		@include media($xLargeNewLayout) {
			margin-top: $base1 * 0.5;
		}

		.newProfileHero.js-openVideo & {
			@include fadeOut();
		}
	}

	&__category {
		@include ease(color, $hoverDuration);
		@include font(newSectionTitle);
		text-decoration: none;
		color: $colorBase;

		[data-type='svg'] > svg {
			fill: none;
			stroke: $colorHighlight;
		}

		@include onHover() {
			color: $colorHighlight;
		}

		.block--vividColorVersion & {
			@include onHover() {
				color: $colorBase;
			}
		}
	}

	&__closeButton {
		@include font(newCategory);
		@include fadeOut();
		@include ease(opacity visibility, $duration2);
		cursor: pointer;
		background-color: transparent;
		border: 0;
		color: $colorHighlight;
		position: absolute;
		bottom: 100%;
		right: 0;
		margin-bottom: $base * 0.7;

		.newProfileHero.js-playVideo & {
			@include media($mediumNewLayout) {
				@include fadeIn();
			}
		}
	}

	&__descrProfile {
		@include font(newHeadingFifthV2);

		a {
			border-bottom: none;
			@include new-alternative-link();
		}
	}

	&__descrProfileWrapper {
		position: relative;
		margin-bottom: $vwS10;
		@include media($mediumNewLayout) {
			margin-bottom: $base0;
		}
	}

	&__iconPlay {
		@include ease(opacity visibility, $duration2);
		pointer-events: none;
		position: absolute;
		display: inline-block;
		top: $base2;
		left: $base2;
		z-index: 2;

		.newProfileHero:not(.newProfileHero--withVideo) &,
		.newProfileHero.js-openVideo & {
			@include fadeOut();
		}

		//>=768px
		@include media($mediumNewLayout) {
			top: $base1;
			left: $base1;
		}

		//>=1280px
		@include media($largeNewLayout) {
			left: $base * 1.5;
			top: $base * 1.5;
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			top: $base2;
			left: $base2;
		}

		[data-type='svg'] > svg {
			transition: 0.3s all;
			fill: $colorHighlight;
			cursor: pointer;
			width: $base6;
			height: $base6;

			.newProfileHero__media:hover & {
				fill: $colorBgBase;
			}

			//>=1280px
			@include media($largeNewLayout) {
				width: $base7;
				height: $base7;
			}

			//>=1680px
			@include media($xLargeNewLayout) {
				width: $base1 * 9;
				height: $base1 * 9;
			}
		}

		&--none {
			display: none;
		}
	}

	&__image {
		@include aspectRatio(math.div(2, 3));
		// @include ease(opacity visibility, $duration2);
		width: 100%;
		// height: 100%;
		left: 0;
		position: relative;
		top: 0;
		max-width: none;
		z-index: 2;
		pointer-events: none;
		// display: none;

		.newProfileHero.js-playVideo & {
			@include fadeOut();
			@include aspectRatio(math.div(16, 9));
		}

		@include media($mediumNewLayout) {
			@include aspectRatio(math.div(16, 9));
			width: staticColumnSize(11, 4, $newGutterSmall);
		}

		@include media($largeNewLayout) {
			width: staticColumnSize(11, 4, $newGutterLarge);
		}

		@include media($xLargeNewLayout) {
			width: staticColumnSize(10, 4, $newGutterLarge);
		}
	}

	&__img {
		@include ease(width, $duration6);
		@include objectFit(cover);
		pointer-events: auto;
		position: absolute;
		height: 100%;
		max-width: none;
		left: 0;
		top: 0;

		.newProfileHero--withVideo & {
			cursor: pointer;
		}

		@include media($mediumNewLayout) {
			width: staticColumnSize(4, 11, $newGutterSmall);
		}

		@include media($largeNewLayout) {
			width: staticColumnSize(4, 11, $newGutterLarge);
		}

		@include media($xLargeNewLayout) {
			width: staticColumnSize(4, 10, $newGutterLarge);
		}

		.newProfileHero.js-openVideo & {
			@include media($mediumNewLayout) {
				width: 100%;
			}

			@include media($largeNewLayout) {
				width: 100%;
			}

			@include media($xLargeNewLayout) {
				width: 100%;
			}
		}
	}

	&__insideWrapper {
		margin-top: $base2;
		@include media($mediumNewLayout) {
			width: staticColumnSize(4, 6, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(4, 7, $newGutterLarge);
			margin-top: $base1 * 3.8;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(4, 6, $newGutterLarge);
			margin-top: $base5;
		}

		&--reverse {
			display: flex;
			flex-direction: column-reverse;
		}
	}

	&__linkProfileWrapper {
		position: relative;
		@include font(newHeadingFifthV2);

		a {
			border-bottom: none;
			@include new-alternative-link();
		}

		&--bottomBorder {
			&::after {
				content: '';
				position: absolute;
				width: 100%;
				height: 1px;
				background: black;
				bottom: -$base2;
			}
		}
	}

	&__linkProfileWrapper + &__descrProfileWrapper {
		&::before {
			content: '';
			position: absolute;
			width: 100%;
			height: 1px;
			background: black;
			top: -$base2;
		}
	}

	&__media {
		position: relative;
		width: 100%;
		margin-top: $vwS10;
		z-index: 1;
		//768
		@include media($mediumNewLayout) {
			width: staticColumnSize(4, 11, $newGutterSmall);
			margin-top: $base0;
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(4, 11, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(4, 10, $newGutterLarge);
		}

		.newProfileHero.js-openVideo & {
			z-index: 3;
		}
	}

	&__wrapperMedia {
		position: relative;
	}

	&__rightContentWrapper {
		position: relative;
		z-index: 2;

		//768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(6, 11, $newGutterSmall);
			margin-left: staticColumnPush(1, 11, $newGutterSmall);
			padding-top: $base5;
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(7, 11, $newGutterLarge);
			margin-left: $base0;
			padding-top: $base4;
			padding-left: $base1 * 2.4;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(6, 10, $newGutterLarge);
			padding-left: $base3;
			padding-top: $base1 * 5.4;
		}
	}

	&__video {
		width: 100%;
		// height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		z-index: 1;
		visibility: hidden;

		@include media($mediumNewLayout) {
			// position: relative;
			width: staticColumnSize(11, 4, $newGutterSmall);
		}

		@include media($largeNewLayout) {
			width: staticColumnSize(11, 4, $newGutterLarge);
		}

		@include media($xLargeNewLayout) {
			width: staticColumnSize(10, 4, $newGutterLarge);
		}

		.newProfileHero.js-playVideo & {
			visibility: inherit;
		}
	}

	&__separator {
		margin: $base0;
		border-top: 1px solid $colorBase;
		margin: {
			top: $vwS10;
			bottom: $vwS10;
		}
		//768px
		@include media($mediumNewLayout) {
			margin: {
				top: $vwM10;
				bottom: $vwM10;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin: {
				top: $vwL20;
				bottom: $vwL20;
			}
		}
		//1680px
		@include media($xLargeNewLayout) {
			margin: {
				top: $base2;
				bottom: $base2;
			}
		}
	}
}
