.newNewsletter {
	position: relative;

	&.js-covered {
		@include app-covered();
	}


	&__content {
		@include app-newcontainer();
		@include staticColumns();
		flex-direction: column;
		padding-top: $newNewsletterSmallPaddingTop;
		position: static;
		width: 100%;

		//>=1280px
		@include media($largeNewLayout) {
			padding-top: $newNewsletterMediumPaddingTop;
			align-items: flex-start;
		}
	}

	&__description,
	&__form {
		width: 100%;
	}

	&__description {
		margin-bottom: $vwS20;

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: $vwM20;
			width: staticColumnSize(7, 8, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL30;
			width: staticColumnSize(6, 8, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			margin-bottom: $base3;
		}
	}

	&__introAndForm {
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(8, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(8, 12, $newGutterLarge);
			margin-right: staticColumnPush(1, 12, $newGutterLarge);
		}
	}

	&__mainContent {
		@include staticColumns();
		align-items: baseline;
		//align-items: flex-start;
		flex-direction: column;
		margin: {
			bottom: $vwS50;
			top: $vwS30;
		}
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			flex-direction: row;
			margin: {
				bottom: $vwM50;
				top: $vwM50;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin: {
				bottom: $vwL100;
				top: $vwL100;
			}
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			margin: {
				bottom: $base10;
				top: $base10;
			}
		}
	}

	&__socialMedia {
		@include staticColumns();
		align-items: flex-start;
		flex-direction: column;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			align-items: center;
			flex-direction: row;
		}
	}

	&__socialMediaTitle {
		@include font(newCategory);
		padding: {
			bottom: $vwS10;
			right: 0;
		}
		text-align: left;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			padding: {
				bottom: 0;
				right: $categoryMediumPadding;
			}
			text-align: right;
			width: staticColumnSize(4, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-right: $vwL20;
			width: staticColumnSize(3, 12, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			padding-right: $base2;
		}
	}

	&__socialMediaMenu {
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(8, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(8, 12, $newGutterLarge);
			margin-right: staticColumnPush(1, 12, $newGutterLarge);
		}
	}

	&__ticker {
		position: absolute;
		right: 0;
		top: 0;
		width: 100%;
		overflow: hidden;
	}

	&__tickerWrapper {
		display: flex;
	}

	&__tickerText {
		@include font(newNewsletterTicker);
		color: $colorBgBase;
		flex-wrap: nowrap;
		display: inline-block;
		white-space: nowrap;
	}

	&__tickerTextLine {
		//margin: 0 $newTickerLineHorizontalSpacing;
		//width: 1px;
	}

	&__title {
		@include font(newCategory);
		padding: {
			bottom: $vwS10;
			right: 0;
			top: 0;
		}
		text-align: left;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			padding: {
				bottom: 0;
				right: $categoryMediumPadding;
				//top: $vwM08;
			}
			text-align: right;
			width: staticColumnSize(4, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				right: $vwL20;
				top: $vwL10;
			}
			width: staticColumnSize(3, 12, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			padding: {
				right: $base2;
				top: $base1;
			}
		}
	}
}
