.share {
	position: relative;
	text-align: center;
	width: 100%;


	* + & {
		margin-top: $base2;
	}


	&__item {
		width: dynamicColumnSize(1, 1, $base4);

		@include media($smallLayout) {
			width: dynamicColumnSize(1, 2, $base4);
		}
	}


	&__items {
		@include dynamicColumns($base4, $base2);
	}


	&__panel {
		@include fadeOut();
		@include ease(opacity visibility, $duration2);
		background-color: $colorBgShare;
		left: 50%;
		margin-top: $base;
		min-width: $base * 28;
		padding: $base2;
		position: absolute;
		text-align: left;
		top: 100%;
		transform: translateX(-50%);
		width: auto;

		@include media($smallLayout) {
			max-width: $base * 60;
			min-width: $base * 28;
			width: 75%;
		}

		.share.js-opened & {
			@include fadeIn();
		}
	}


	&__panelTitle {
		@include font(baseSmall);
		font-family: $fontFamilyAlt;
		font-weight: 600;
		margin-bottom: $base;
	}
}
