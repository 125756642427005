$colorHighlight: #ff6955;
$colorHighlightLight: #ffa498;


$colorBase: #000000;
$colorBgBase: #ffffff;
$colorInverted: $colorBgBase;
$colorBgInverted: $colorBase;
$colorAlternative: $colorHighlight;
$colorBgAlternative: #AAAAAA;
$colorBgSecondAlternative: #636262;
$colorSeparatoraAccordion: #808080;
$colorSubTitle: $colorBgSecondAlternative;
$colorBgModal: $colorBgBase;

$darkColorBg: $colorBase;
$lightColorBg: #f0f0f0;
$veryLightColorBg: $colorBgBase;
$vividColorBg: $colorHighlight;

$colorInput: $colorBase;
$colorBgInput: $lightColorBg;
$newColorBgInput: #FFA498;
$colorBgVideo: $colorBgInput;
$colorInputBorder: $colorBase;
$newColorFormElemBorder: #ffb4aa;
$colorError: #FF0000;

$colorInactive: #dcdcdc;
$colorUgcSeparator: #c4c4c4;

$colorBgShare: #dcdcdc;

$colorSubTitleVivid: #d7d7d7;

$colorBgModalCover: transparentize($colorBgInverted, 0.4);

$colorBgTeam: $lightColorBg;
$colorBgTrackers: $colorBgTeam;

$hoverColor: $colorHighlight;
$hoverColorBgInput: $colorInactive;

$newColorBorderBase: #B2B2B2;

$newColorBorderDark: #b2b2b2;
$newColorBorderLight: $colorBgSecondAlternative;
$newColorBorderVeryLight: $colorBgBase;

$newColorBgMenuToggler: $lightColorBg;

$newMenuColorBorder: #e5e5e5;
