@use "sass:math";

.newPersonItem {
	margin: {
		bottom: 0;
		right: 0;
	}
	width: 100%;

	//>=768px
	@include media($mediumNewLayout) {
		margin: {
			bottom: $vwM50;
			//right: $newGutterSmall;
		}
		width: dynamicColumnSize(4, 12, $newGutterSmall);
	}

	//>=1280px
	@include media($largeNewLayout) {
		margin: {
			bottom: $vwL100;
			//right: $newGutterLarge;
		}
		width: dynamicColumnSize(2, 10, $newGutterLarge);
	}

	//>=1680px
	@include media($xLargeNewLayout) {
		margin-bottom: $base10;
	}

	.js-imgNotScale {
		@include ease(transform, $duration3, $easeOutQuad);
		transform: scale(1);
	}

	.js-imgScale {
		@include ease(transform, $duration3, $easeOutQuad);
		transform: scale($scaleImg);
	}

	/*&:nth-of-type(3) {
		//>=768px
		@include media($mediumNewLayout) {
			margin-right: 0;
		}

		//>=1280px
		@include media($largeNewLayout) {
			margin-right: $newGutterLarge;
		}
	}

	&:nth-of-type(5) {
		//>=1280px
		@include media($largeNewLayout) {
			margin-right: 0;
		}
	}*/

	&__academy {
		@include font(newBaseSmall);
		margin-top: 0;

		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL05;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $xSmallVerticalSpacing;
		}
	}

	&__linkLine {
		.newPersonItem__linksWrapper &:last-child {
			display: none;
		}
		padding-right: $baseHalf;
	}

	&__linkText {
		//@include new-alternative-link();
		display: inline;
		padding-right: $baseHalf;
		position: relative;
		color: $colorHighlight;
		text-decoration: none;

		.newPersonItem__linksWrapper &:first-child {
			padding-left: 0;
		}

		border-bottom: {
			color: transparent;
			style: solid;
			width: 1px;
		}

		@include onHover() {
			border-bottom-color: $colorHighlight;
		}
	}

	&__linksWrapper {
		@include font(newBaseSmall);
		color: $colorHighlight;
		margin-top: $vwS10;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL15;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $smallMarginTop;
		}
	}

	&__contentWrapper {
		margin-top: $vwS10;

		@include media($mediumNewLayout) {
			margin-top: $vwM15;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL20;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base2;
		}
	}

	&__image {
		@include objectFit(cover);
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&__imageWrapper {
		@include aspectRatio(math.div(5, 7));
		overflow: hidden;
		position: relative;
	}

	&__img {
		@include ease(transform, $duration3, $easeOutQuad);
		@include objectFit(cover);
		min-height: 100%;
		width: 100%;

		@include onHover('.newPersonItem__imgLink', ' &') {
			transform: scale($scaleImg);
		}
	}

	&__imgLink {
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&__link {
		@include font(newBaseSmall);
		@include new-alternative-link();
		display: block;
		margin-top: $vwS10;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL15;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $smallMarginTop;
		}
	}

	&__name {
		@include font(newHeadingFifth);
	}

	&__nameLink {
		@include new-title-link();
	}
}
