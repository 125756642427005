.subMenu {
	display: block;
	position: relative;

	&__content {
		display: block;
		height: 0;
		overflow: hidden;
		position: relative;

		&.js-active {
			display: block;
			height: auto;
		}

		.newMainMenu__desktopSub & {
			//height: 100%;
			padding-left: 0;

			//>=768px
			@include media($mediumNewLayout) {
				padding-left: $vwM30;
			}

			//>=1280px
			@include media($largeNewLayout) {
				padding-left: $vwL50;
			}

			//>=1680px
			@include media($xLargeNewLayout) {
				padding-left: $base5;
			}

			&::before {
				@include ease(opacity height, $durationBase, $easeBase);
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				display: block;
				width: 1px;
				//opacity: 0;
				height: 0;
				background-color: $newMenuColorBorder;
			}

			&.js-active {
				&::before {
					opacity: 1;
					height: 100%;
				}
			}
		}
	}

	&__items {
		.newMainMenu__desktopSub & {
			@include media($largeNewLayout) {
				margin-top: $base2;
			}

			//>=1680px
			@include media($xLargeNewLayout) {
				margin-top: $base2;
			}
		}

		.newMainMenu__extraNav & {
			@include staticColumns();
			justify-content: flex-start;
			margin: {
				bottom: 0;
				top: 0;
			}

			//>=768px
			@include media($mediumNewLayout) {
				padding-left: 0;
			}
		}
	}


	.newMainMenu__desktopSub & {
		height: 100%;
	}
}
