.slide {
	display: flex;
	flex-direction: column;
	flex-grow: 0;
	flex-shrink: 0;
	position: relative;
	width: 100%;

	.slideshow--animationTypeFade & {
		@include ease(opacity visibility, $slideshowFadeDuration);
		@include fadeOut();
		z-index: 1;

		@for $index from 0 through ($slideshowMaxItems - 1) {
			&:nth-child(#{$index + 1}) {
				transform: translateX(#{-100% * $index});
			}
		}

		&.js-current {
			@include fadeIn();
			z-index: 2;
		}
	}

	.slideshow--animationTypeSlide .slideshow__items.js-animate & {
		@include ease(transform, $slideshowFadeDuration);
	}

	.slideshow--animationTypeSlide.slideshow--circular & {
		$balancedLimit: ceil($slideshowMaxItems * 0.5);

		@for $index from 0 through ($slideshowMaxItems - 1) {
			@for $position from 0 through $balancedLimit {
				&:nth-child(#{$index + 1})[data-balanced-position="#{$position}"] {
					transform: translateX(#{-100% * ($index - $position)});
				}

				&:nth-child(#{$index + 1})[data-balanced-position="-#{$position}"] {
					transform: translateX(#{-100% * ($index + $position)});
				}
			}
		}
	}

	.slideshow--animationTypeSlide:not(.slideshow--circular) & {
		@for $index from 0 through ($slideshowMaxItems - 1) {
			@for $position from 0 through ($slideshowMaxItems - 1) {
				&:nth-child(#{$index + 1})[data-position="#{$position}"] {
					transform: translateX(#{-100% * ($index - $position)});
				}

				&:nth-child(#{$index + 1})[data-position="-#{$position}"] {
					transform: translateX(#{-100% * ($index + $position)});
				}
			}
		}
	}

	&__category {
		@include font(slideAltTitle);
		font-weight: bold;
		display: inline-block;

		.block--slideshow.block--slideshowStyleAlternative & {
			@include font(slideCategory);
			text-transform: uppercase;
			margin-bottom: $base2;
			margin-top: $base3;

			@include media($columnsBreakpoint) {
				margin-top: 0;
				margin-bottom: $base3;
			}
		}
	}


	&__container {
		padding-top: $base3;
		padding-bottom: 0;
		position: relative;
		width: 100%;
		flex-grow: 2;
		z-index: 1;
		display: flex;
		text-align: center;

		@include media($smallLayout) {
			padding-top: $blockVerticalSpacingSmall;
			padding-bottom: 0;
		}

		// making the text selectable only when you are touching the text elements
		> * {
			.slide:active & {
				user-select: none;
			}

			.slide:active &:active {
				user-select: auto;
			}
		}

		.block--slideshow.block--slideshowStyleAlternative & {
			padding-top: 0;
		}
	}


	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;

		.block--slideshow.block--slideshowStyleAlternative & {
			width: 100%;
			position: relative;

			@include media($columnsBreakpoint) {
				background-color: $colorBgBase;
				bottom: 100%;
				left: 0;
				position: absolute;
				width: staticColumnSize(3, 6, $gutterMedium);
			}
		}
	}

	&__figure {
		height: auto;
		width: 100%;
		z-index: 0;

		.slideshow:not(.slideshow--minHeightBg) & {
			bottom: 0;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
		}
	}

	&__header {
		.block--slideshow.block--slideshowStyleAlternative & {
			padding: 0;

			@include media($columnsBreakpoint) {
				padding: $base4 $base4 0 $base4;
			}
		}
	}

	&__title {
		@include font(slideAltTitle);
		display: inline-block;
		font-weight: normal;
		margin-left: $base1;

		.block--slideshow.block--slideshowStyleAlternative & {
			display: block;
			@include font(slideTitle);
			margin-left: 0;
			margin-bottom: $base2;
			color: $colorAlternative;

			@include media($columnsBreakpoint) {
				margin-top: 0;
				margin-bottom: $base4;
			}
		}
	}


	&__subtitle {
		@include font(slideAltTitle);
		display: inline-block;

		.block--slideshow.block--slideshowStyleAlternative & {
			display: block;
			@include font(slideSubtitle);
			text-transform: uppercase;
		}
	}


	&__text {
		// @include app-container(true);

		display: none;
		// position: relative;
		// top: calc(100% + 6rem);
		// margin: 0 auto 0 auto;

		// @include media($mediumLayout) {
		// 	width: staticColumnSize(10, 12, $gutterMedium);
		// }

		// @include media($largeLayout) {
		// 	width: staticColumnSize(8, 12, $gutterLarge);
		// }
	}
}
