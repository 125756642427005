// BASE

@use "sass:math";

$base: 1rem;
$base0: $base * 0;
$base1: $base * 1;
$base2: $base * 2;
$base3: $base * 3;
$base4: $base * 4;
$base5: $base * 5;
$base6: $base * 6;
$base7: $base * 7;
$base8: $base * 8;
$base9: $base * 9;
$base10: $base * 10;
$base150: $base10 + $base5;
$baseHalf: $base * 0.5;

$vwS: 3vw;
$vwS05: $vwS * 0.5;
$vwS10: $vwS * 1;
$vwS15: 4.7vw; //special case
$vwS20: $vwS * 2;
$vwS30: $vwS * 3;
$vwS50: $vwS * 5;

$vwM: 1vw;
$vwM05: $vwM * 0.5;
$vwM10: $vwM * 1;
$vwM15: $vwM * 1.5;
$vwM20: $vwM * 2;
$vwM30: $vwM * 3;
$vwM50: 4.8vw; //special case
$vwM60: 5.9vw; //navigation only
$vwM100: 9.7vw; //special case

$vwL: 0.6vw;
$vwL05: $vwL * 0.5;
$vwL10: $vwL * 1;
$vwL15: $vwL * 1.5;
$vwL20: $vwL * 2;
$vwL25: $vwL * 2.5;
$vwL30: $vwL * 3;
$vwL50: $vwL * 5;
$vwL100: $vwL * 10;
$vwL150: $vwL * 15;

$videoRatio: math.div(16, 9);
$videoRatioNew: math.div(4, 3);
$videoRatioNewMin: math.div(4, 2);

$scaleImg: 1.05;

$sliderProgressTop: $vwL * 23.4;
$newLogoHeightSmall: 11.9vw;
$newLogoHeightMedium: $vwM * 5.9;
$newLogoHeightLarge: 4.063vw;
$newLogoFooterHeight: 10vw;
$categoryMediumPadding: $vwM * 0.1;
$largeHeaderTopLarge: $vwL * 20.3;

// LAYOUT

$containerPaddingXSmall: $base2;
$containerPaddingSmall: $base5;
$containerPaddingMedium: $base7;
$containerPaddingLarge: $base * 10;

$newContainerPaddingSmall: $base;
$newContainerPaddingMedium: $base3;
$newContainerPaddingLarge: $base4;

$categoryTopMargin: $base * 2.3;
$newCategoryTopMargin: $base1 + 0.1;

$containerMaxWidth: 128 * $base;
$containerMaxWidthNarrow: 66 * $base;
$containerMaxWidthWide: (10 + 118 + 10) * $base;
$newContainerMaxWidth: 168 * $base;

// vertical top space between blocks
$blockVerticalSpacingSmall: $base5;
$blockVerticalSpacingLarge: $base * 10;

$blockVerticalSpacingSmallAlternate: $base3;
$blockVerticalSpacingLargeAlternate: $base5;

$blockVerticalSpacingSmallAlternate2: $base2;
$blockVerticalSpacingLargeAlternate2: $base3;

// vertical top space of nested blocks
$nestedBlockVerticalSpacingSmall: $base5;
$nestedBlockVerticalSpacingLarge: $base * 10;

$nestedBlockVerticalSpacingSmallAlternate: $base5;
$nestedBlockVerticalSpacingLargeAlternate: $base7;

// header content distance
$blockHeaderTopSpacingSmall: $base2;
$blockHeaderTopSpacingLarge: $base3;

$blockHeaderTopSpacingSmallAlternate: $base3;
$blockHeaderTopSpacingLargeAlternate: $base5;

//section header (AKA Separator)
$blockVerticalSpSmallSeparator: $base7;
$blockVerticalSpLargeSeparator: $base10;

$teaserVerticalSpacingSmall: $base5;
$teaserVerticalSpacingLarge: $base * 20;

$firstBlockTopSpacingSmall: $base * 13;
$firstBlockTopSpacingLarge: $base * 16.5;
$teaserMinHeight: 80vh;
$teaserMinHeightIeFix: 79vh; // 1 unit less than the previous

$teaserCaptionPaddingSmall: $baseHalf;
$teaserCaptionPaddingLarge: $base1;

$blockHeaderContentSpacingSmall: $base2;
$blockHeaderContentSpacingLarge: $base3;

$gutterSmall: $base1;
$newGutterSmall: $base1;
$newGutterLarge: $base2;
$gutterMedium: $base3;
$gutterLarge: $base5;

// HEADER
$headerSpacingSmall: $base2;
$headerSpacingSmall: $headerSpacingSmall - 0.5;
$headerSpacingMedium: $base3;
$headerSpacingMedium: $headerSpacingSmall - 0.5;
$headerSpacingLarge: $base5;
$headerSpacingLarge: $headerSpacingSmall + 0.2;

$logoHeightSmall: $base * 4.4;
$logoHeightLarge: $base * 6.6;
$newLogoHeightXLarge: $base7 + 0.1;

$mainMenuTopSpacingSmall: $headerSpacingSmall * 2 + $logoHeightSmall;
$mainMenuTopSpacingMedium: $headerSpacingMedium * 2 + $logoHeightSmall;
$mainMenuTopSpacingLarge: $headerSpacingLarge * 2 + $logoHeightLarge;

$menuTopSpacing: $base7;

$hamburgerWidth: $base * 2.6;
$hamburgerWidthShort: $base2;
$hamburgerHeight: $base2;

$headerNavSmallPosTop: $base1 - 0.1;
$headerNavMediumPosTop: $base3 - 0.1;
$headerNavLargePosTop: $base8 - 0.4;
$headerNavXLargePosTop: $base10 - 0.2;

$headerNavSmallHeight: 12.5vw;
$headerNavMediumHeight: 6.12vw;
$headerNavLargeHeight: 4.219vw;
$headerNavXLargeHeight: $base7 + 0.5;

$newMenuLangSmallRightPos: $base * 11.8; // 118px;
$newMenuLangMediumRightPos: $base * 13.3; // 133px;
$newMenuLangLargeRightPos: $base * 12.9; // 129px;
$newMenuLangXLargeRightPos: $base * 14.4; // 144px;

$newMenuLangMarginRight: -($base1 - 0.3);
$menuRotationDeg: 90deg;
$newMenuLangLineRotationDeg: 35deg;

$newNavLinkArrowHeight: $base1 + 0.2;

$newSocialMenuItemHeight: $base4 + 0.2rem;
$newSocialMenuLinkHeight: $base3 + 0.2rem;

$mediumTitleLineHeight: $base * 2.8;
$largeTitleLineHeight: $base * 5.5;

// BLOCKS
$accordionHeight: $base * 4.7;

$newProgressBarHeight: $base - 0.9rem;
$newProgressInnerHeight: $base - 0.7rem;

$xSmallVerticalSpacing: $base - 0.5rem;
$smallHorizontalSpacing: $base1 * 1.3;
$smallVerticalSpacing: $base1 * 1.4;
$smallMarginTop: $base1 * 1.5;
$smallPaddingTop: $base1 + 0.3;

$mediumHorizontalSpacing: $base1 * 1.7;
$mediumVerticalSpacing: $base2 + 0.3;
$mediumSpacing: $base2 + 0.5;

$largeHorizontalSpacing: $base2 + 0.7;
$largeVerticalSpacing: $base * 3.8;

$xLargeVerticalSpacing: ($base * 10) + $base6 + $xSmallVerticalSpacing;

$videoTitleSpacingSmall: $base * 1.5;
$videoTitleSpacingLarge: $base2;

$galleryJustifiedItemMinHeight: $base * 30;
$galleryColumnsNumber: 2;
$galleryColumnsImageRatio: 3 * 0.5;

$slideshowMaxItems: 20;
$slideShowNavigationTopSpacingSmall: $base1;
$slideShowNavigationTopSpacingLarge: $base * 1.5;

$slideShowNavigationDotSizeSmall: $base * 0.6;
$slideShowNavigationDotSizeLarge: $base1;

$slideMinHeight: 60vh;
$slideRatio: math.div(16, 9);
$slideGap: $gutterMedium;

$inputPadding: $base * 0.65;
$inputNestedPadding: $inputPadding - 0.5;
$labelNestedPadding: $base * 0.4;
$inputToLabelVerticalMargin: $baseHalf;
$inputBorderSize: 1px;
$buttonBorderSize: $inputBorderSize;
$buttonMinWidth: $base * 16;
$radioOuterSize: $base2;
$radioInnerSize: $base * 1.2;
$radioInnerScale: 0.5;
$boxBorderSize: 2px;
$newFormElemLargeSpacing: $base1 + 0.4;
$newFormElemMediumSpacing: $base1 + 0.3;
$newFormElemSmallSpacing: $newFormElemMediumSpacing - 0.1;
$newFormCheckHorizontalSpacing: $base3 + 0.2;
$newCheckboxSize: $base2 + 0.2;
$newCheckboxPadding: $base1 - 0.6;

$formFieldFullColumns: 8; // number of columns, 12 = full width

$mapHeightSmall: 50 * $base;
$mapHeightLarge: 50 * $base;

$hrSize: 1px;
$textMaxWidth: $base * 100;

$footerTabPaddingLarge: $base2;

$contentTeaserCategoryVerticalSpacing: $base1;

$teamSpace: $base5;
$figureTopPadding: $base4;

$newMediumMaxHeight: $base * 72.8;
$newLargeHeaderTop: $base * 20.2;
$newWrapperProgressTop: $base * 24;

$newLinkMarginRight: $base - 0.3;

// ICONS
$spinningIconSize: $base5;
$spinningIconBorderSize: $base * 0.8;

$buttonIconBarSize: 3px;
$menuTogglerSizeSmall: $base * 2.5;
$menuTogglerDiagonalSizeSmall: $base * 3.53; //menuTogglerSizeSmall √2
$menuTogglerSizeLarge: $base * 2.8;
$menuTogglerDiagonalSizeLarge: $base * 3.95; //menuTogglerSizeLarge √2

$newsletterAnchorSpacing: $base * 3.4;
$newsletterAnchorIconSize: $base * 1.2;

$newNewsletterMediumPaddingTop: $base4 + 0.8;
$newNewsletterSmallPaddingTop: $base3 + 0.4;

$newTickerLineHorizontalSpacing: $base3 + 0.5;

$newIconPlusHeight: pxToRem(1);
$newIconPlusWidth: pxToRem(7);

$sizeVariants: (
	blockVerticalSpacings: (
		margin-top: $blockVerticalSpacingSmall,
		variants: (
			$mediumLayout: (
				margin-top: $blockVerticalSpacingLarge,
			),
		),
	),
	blockVerticalSpacingsAlternate: (
		margin-top: $blockVerticalSpacingSmallAlternate,
		variants: (
			$mediumLayout: (
				margin-top: $blockVerticalSpacingLargeAlternate,
			),
		),
	),
	blockVerticalSpacingsAlternate2: (
		margin-top: $blockVerticalSpacingSmallAlternate2,
		variants: (
			$mediumLayout: (
				margin-top: $blockVerticalSpacingLargeAlternate2,
			),
		),
	),
	blockVerticalSpacingsAlternate3: (
		margin-top: $blockVerticalSpacingSmall,
		variants: (
			$smallLayout: (
				margin-top: $blockVerticalSpacingLarge,
			),
		),
	),
	verticalSpacingsWithLinks: (
		margin-top: $teaserVerticalSpacingSmall,
		margin-bottom: 0,
		variants: (
			$mediumLayout: (
				margin-top: $blockVerticalSpLargeSeparator,
				margin-bottom: 0,
			),
			$largeLayout: (
				margin-top: $firstBlockTopSpacingSmall,
			),
		),
	),
	firstBlockVerticalSpacings: (
		margin-top: $firstBlockTopSpacingSmall,
		margin-bottom: $blockVerticalSpacingSmallAlternate,
		variants: (
			$mediumLayout: (
				margin-top: $firstBlockTopSpacingLarge,
				margin-bottom: $blockVerticalSpacingLargeAlternate,
			),
		),
	),
	blockVerticalSpacingsSeparator: (
		margin-top: $blockVerticalSpSmallSeparator,
		variants: (
			$smallLayout: (
				margin-top: $blockVerticalSpLargeSeparator,
			),
		),
	),
	teaserVerticalSpacings: (
		margin-top: $teaserVerticalSpacingSmall,
		variants: (
			$mediumLayout: (
				margin-top: $teaserVerticalSpacingLarge,
			),
		),
	),
	mainMenuVerticalSpacings: (
		padding-top: $mainMenuTopSpacingSmall,
		variants: (
			$smallLayout: (
				padding-top: $mainMenuTopSpacingMedium,
			),
			$mediumLayout: (
				padding-top: $mainMenuTopSpacingLarge,
			),
		),
	),
	headerVerticalSpacings: (
		padding-bottom: $headerSpacingSmall,
		padding-top: $headerSpacingSmall,
		variants: (
			$smallLayout: (
				padding-bottom: $headerSpacingMedium,
				padding-top: $headerSpacingMedium,
			),
			$mediumLayout: (
				padding-bottom: $headerSpacingLarge,
				padding-top: $headerSpacingLarge,
			),
		),
	),
	footerVerticalSpacings: (
		padding-top: $base4,
		padding-bottom: $base10,
		variants: (
			$smallLayout: (
				padding-top: $base5,
			),
			$mediumLayout: (
				padding-top: $base10,
			),
		),
	),
);

$containerPaddings: (
	$smallLayout: $containerPaddingSmall,
	$mediumLayout: $containerPaddingMedium,
	$largeLayout: $containerPaddingLarge,
);
