.newTitle {
	position: relative;
	align-items: baseline;
	width: 100%;

	//>=768px
	@include media($mediumNewLayout) {
		width: staticColumnSize(11, 12, $newGutterLarge);
		@include staticColumns();
	}
	//>=1280px
	@include media($largeNewLayout) {
		width: staticColumnSize(10, 12, $newGutterLarge);
	}

	&__header {
		@include font(newCategory);
		color: $colorBgBase;
		text-align: start;
		padding: {
			bottom: $vwS10;
		}

		//>=768px
		@include media($mediumNewLayout) {
			padding: {
				right: $categoryMediumPadding;
				bottom: 0;
			}
			text-align: right;
		}

		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				right: $vwL20;
			}
		}

		//>=1440PX
		@include media($xLarge2vNewLayout) {
			padding-right: $base2;
		}

		.block--lightColorVersion &,
		.block--veryLightColorVersion & {
			color: $colorBase;
		}
	}

	&__headerLink {
		text-decoration: none;
		height: min-content;

		[data-type='svg'] > svg {
			fill: none;
			stroke: $colorHighlight;

			.block--vividColorVersion & {
				stroke: $colorBase;
			}
		}

		&:hover {
			color: $colorHighlight;
			transition: 0.3s all; //TODO
			.block--vividColorVersion & {
				color: $colorBase;
			}
		}
	}

	&__headerWrapper {
		width: 100%;
		display: flex;
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(4, 11, $newGutterSmall);
			height: $base4 + 0.912;
			justify-content: flex-end;
		}

		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(3, 10, $newGutterLarge);
			height: $base8 + 0.455;
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			height: $base9 + 0.5;
		}
	}


	&__title {
		@include font(newMainNavFirstLevel);
		color: $colorBgBase;
		//768px
		@include media($mediumNewLayout) {
			width: auto;
		}

		.block--lightColorVersion &,
		.block--veryLightColorVersion & {
			color: $colorBase;
		}
	}

	&__topWrapper {
		width: 100%;
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(7, 11, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(7, 10, $newGutterLarge);
		}
	}

	&__subTitle {
		@include font(newSubTitle);
		margin-top: $vwS05;
		color: $newColorBorderBase;
		//768px
		@include media($mediumNewLayout) {
			width: auto;
		}

		.block--vividColorVersion & {
			color: $colorSubTitleVivid;
		}

		.block--lightColorVersion &,
		.block--veryLightColorVersion & {
			color: $colorSubTitle;
		}
	}

	&__redText {
		color: $colorBgBase;

		.block--lightColorVersion &,
		.block--veryLightColorVersion & {
			color: $colorBase;
		}
	}
}
