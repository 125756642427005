@use "sass:math";

.newEditorialHero {
	position: relative;

	&--noMedia {
		align-items: baseline;
	}

	//>=768px
	@include media($mediumNewLayout) {
		@include staticColumns();
	}

	&__altText {
		@include font(newAltTextEditor);
		margin-top: $vwS05;

		.newEditorialHero__mediaWrapper--narrow & {
			@include media($largeNewLayout) {
				width: 100%;
				margin-left: 0;
			}

			@include media($xLargeNewLayout) {
				width: 100%;
				margin-left: 0;
			}

			@include media($xxLargeNewLayout) {
				width: 100%;
				margin-left: 0;
			}
		}

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM05;
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(5, 6, $newGutterLarge);
			margin-left: staticColumnPush(1, 6, $newGutterLarge);
			margin-top: $vwL05;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $baseHalf;
			width: staticColumnSize(5, 6, $newGutterLarge);
			margin-left: calc((staticColumnPush(1, 6, $newGutterLarge)) - 7);
		}
		//>=1920px
		@include media($xxLargeNewLayout) {
			width: staticColumnSize(5, 6, $newGutterLarge);
			margin-left: staticColumnPush(1, 6, $newGutterLarge);
		}
	}

	&__altTextWrapper {
		.newEditorialHero__mediaWrapper--narrow & {
			@include media($largeNewLayout) {
				margin-left: 0;
			}
		}

		@include media($largeNewLayout) {
			margin-left: $newContainerPaddingLarge;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-left: 0;
		}
	}

	&__breadcrumb {
		@include ease(color, $hoverDuration);
		text-decoration: none;
		color: $colorBase;

		[data-type="svg"] > svg {
			fill: none;
			stroke: $colorHighlight;

			.block--vividColorVersion & {
				stroke: $colorBase;
			}
		}

		//todo
		span:last-child {
			color: $colorHighlight;

			.block--vividColorVersion & {
				color: $colorBase;
			}
		}

		@include onHover() {
			color: $colorHighlight;
		}

		.block--vividColorVersion & {
			color: $colorBgBase;
			@include onHover() {
				color: $colorBase;
			}
		}

		.block--darkColorVersion & {
			color: $colorBgBase;
			@include onHover() {
				color: $colorHighlight;
			}
		}
	}

	&__categoryInner {
		@include media($mediumNewLayout) {
			align-self: flex-end;
			position: absolute;
			bottom: -6px;
			right: 0;
		}
	}

	&__category {
		@include font(newSectionTitle);
		color: $colorBase;
		text-transform: uppercase;
		margin: {
			top: $vwS30;
		}
		//>=768px
		@include media($mediumNewLayout) {
			margin: {
				top: $base0;
			}
		}

		&--image {
			//>=768px
			@include media($mediumNewLayout) {
				position: relative;
			}
		}

		&--none {
			display: none;
		}

		.newEditorialHero__categoryWrapper--noMedia & {
			//>=768px
			@include media($mediumNewLayout) {
				//align-self: flex-end;
				//position: absolute;
				bottom: 0;
				right: 0;
			}
		}

		.newEditorialHero__categoryWrapper & {
			//>=768px
			@include media($mediumNewLayout) {
				text-align: right;
				padding: {
					right: $categoryMediumPadding;
				}
			}
			//>=1280px
			@include media($largeNewLayout) {
				padding: {
					right: $vwL20;
				}
			}
			//>=1440PX
			@include media($xLargeNewLayout) {
				padding-right: $base2;
			}
		}

		.block--darkColorVersion &,
		.block--vividColorVersion & {
			color: $colorBgBase;
		}
	}

	&__category + &__category {
		margin-top: $vwS05;
		@include media($mediumNewLayout) {
			margin-top: $vwM05;
		}
		//>=1680px
		@include media($largeNewLayout) {
			margin-top: $baseHalf;
		}
	}

	&__categoryWrapper {
		position: relative;
		//height: min-content;

		align-items: baseline;

		&--noMedia {
			//>=768px
			@include media($mediumNewLayout) {
				height: $mediumTitleLineHeight;
				display: flex;
				justify-content: flex-end;
				flex-direction: column;
			}

			//>=1280px
			@include media($largeNewLayout) {
				height: $largeTitleLineHeight;
			}
		}

		&--none {
			display: none;
		}

		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(4, 12, $newGutterSmall);
			//height: $base2 + 0.6;
			//	height: $base * 2.6;
		}
		//>=1280px
		@include media($largeNewLayout) {
			//	height: $base5;
			//	height: $base * 5.2;
			width: staticColumnSize(3, 12, $newGutterLarge);
		}
	}

	&__description {
		@include font(profileDescription);
		margin-top: $vwS05 * 0.5;
		width: 100%;

		@include media($mediumNewLayout) {
			margin-top: $vwM10 * 0.5;
		}

		@include media($largeNewLayout) {
			margin-top: $vwL20 * 0.5;
			padding-right: staticColumnSize(1, 6, $newGutterLarge);
		}

		.newEditorialHero__textWrapper--noneMedia & {
			@include media($mediumNewLayout) {
				padding-right: 0;
				width: staticColumnSize(5, 9, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				padding-right: 0;
				width: staticColumnSize(5, 9, $newGutterLarge);
			}
		}
	}

	&__image {
		width: 100%;
		height: auto;
	}

	&__media {
		overflow: hidden;
		position: relative;
	}

	&__mediaWrapper {
		position: relative;
		overflow: hidden;
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(6, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: calc(
				staticColumnSize(6, 12, $newGutterLarge) + $newContainerPaddingLarge
			);
			margin-left: -$newContainerPaddingLarge;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(6, 12, $newGutterLarge);
			margin-left: 0;
		}

		&--narrow {
			//>=768px
			@include media($mediumNewLayout) {
				width: staticColumnSize(6, 12, $newGutterSmall);
				margin-left: $base0;
			}
			//>=1280px
			@include media($largeNewLayout) {
				width: staticColumnSize(5, 12, $newGutterLarge);
				margin-left: staticColumnPush(1, 12, $newGutterLarge);
			}
		}
	}

	&__textWrapper {
		position: relative;
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(6, 12, $newGutterSmall);
			padding: {
				//
				left: $vwM10;
			}
		}
		//>=1024px
		@include media($mediumLayout) {
			padding: {
				//left: $vwM;
			}
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(6, 12, $newGutterLarge);
			padding: {
				left: $vwL30;
			}
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(6, 12, $newGutterLarge);
			padding: {
				left: $base3;
			}
		}

		&--noneMedia {
			padding: {
				left: $base0;
			}
			//>=768px
			@include media($mediumNewLayout) {
				width: staticColumnSize(8, 12, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				width: staticColumnSize(9, 12, $newGutterLarge);
			}
		}
	}

	&__titleSection {
		@include responsiveProps(
			$margin-top: ($vwS05, $vwM50, $vwL50, $base5)
		);
	}

	&__dates {
		@include font(newCategory);
		time + time {
			&:before {
				content: '–';
				color: $colorHighlight;
			}
		}
		@include responsiveProps(
			$margin-bottom: (5px, 7px, 4px, 5px),
		);
	}

	&__timeAndPlace {
		@include font(profileDescription);
		> span + span {
			&:before {
				content: ' | ';
				color: $colorHighlight;
			}
		}
		@include responsiveProps(
			$margin-top: (10px, 20px, 28px, 50px),
			$margin-bottom: (10px, 10px, 7px, 9px),
		);
	}

	&__title {
		@include font(newTitileEditor);
		.newEditorialHero__textWrapper--noneMedia & {
			//>=768px
			@include media($mediumNewLayout) {
				margin: {
					top: $base0;
				}
				width: staticColumnSize(7, 9, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				width: staticColumnSize(7, 9, $newGutterLarge);
			}
			//>=1440px
			@include media($xLarge2vNewLayout) {
				width: staticColumnSize(6, 9, $newGutterLarge);
			}
		}

		&--resetMarginTop {
			margin-top: $base0;
		}
	}

	&__video {
		width: 100%;
		height: 100%;
	}
	//TODO video not displayed ??
	&__videoElemnt {
		@include ease(transform, $duration3, $easeOutQuad);
		@include objectFit(cover);
		min-height: 100%;
	}
}
