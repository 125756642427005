.animatedHeadline {
	&__word {
		display: inline-block;
		//overflow: hidden;
	}

	&__wordText {
		display: block;

		transform: translateY(120%);
		opacity: 0;

		@for $i from 1 through 30 {
			.animatedHeadline__word--delay#{$i} & {
				@include ease(transform opacity, $durationBase, $easeOutQuad, $duration05 * $i);
			}
		}

		.animatedHeadline.js-intersected & {
			transform: translateY(0);
			opacity: 1;
		}
	}
}
