.image-info {
	position: relative;

	&__caption {
		cursor: pointer;
		float: right;
		transition: color 0.5s;
		.block--decorationPattern3 & {
			float: left;
		}
		.block--decorationPattern4 & {
			float: left;
		}

		&:hover {
			color: $colorAlternative;
		}
	}
	&__label {

	}
	&__dot {
		vertical-align: bottom;
		display: inline-block;
		width: 13px;
		height: 13px;
		border-radius: 10px;
		border: 3px solid #fdb5b0;
		background-color: $colorAlternative;
		animation: pulse 3s;
		animation-timing-function:ease-in-out;
		animation-iteration-count: infinite;
	}
	@keyframes pulse {
		0% {background-color: $colorAlternative}
		50% {background-color: #fdb5b0}
		100% {background-color: $colorAlternative}
	}
	&__popup {
		z-index: 1;
		visibility: hidden;
		position: absolute;
		right: 0;
		left: 0;
		bottom: 0;
		margin: 0 auto 0 auto;
		background: $colorBgInput;
		max-width: 600px;
		width:63vw;
		padding: $containerPaddingXSmall 5rem $containerPaddingXSmall $containerPaddingXSmall;
		transition: bottom 0.5s, opacity 0.5s;
		opacity: 0;
		&.js-active {
			visibility: visible;
			bottom: 35px;
			opacity: 1;
		}
	}
	&__info {
		font-family: $fontFamilyAlt;
		font-weight: $weightRegular;
		font-size: pxToRem(14);
		line-height: 1.2;
		letter-spacing: 0.05rem;
		text-align: left;
		max-height: 62vh;
		overflow: auto;
		display: none;

		&.js-active {
			display: block;
		}
		p {
			margin-bottom: $base;
		}

	}
}
