// Style for User Generated Content
.ugc {
	&__caps {
		text-transform: uppercase;
		font-size: 0.9em;
	}


	a {
		@include app-link();
		text-decoration: underline;
		font-family: $fontFamilyAlt;
		font-weight: $weightSemiBold;
	}

	&.block__subtitle a {
		font-weight: inherit;
		font-family: inherit;
	}


	a.ugc__link {
		&--button {
			display: inline-flex;
			justify-content: center;
			pointer-events: none;
			text-decoration: none;
			width: 100%;
		}
	}

	&__linkLabel {
		.ugc__link--button & {
			background-color: $colorBgInput;
			padding: $base1 $base2;
			pointer-events: all;
			@include font(detailSemiBold);
		}

		.block--fontSizeSmall & {
			@include font(baseSmallSemiBold);
		}
	}

	&__linkWrapper {
		display: flex;
		align-items: center;

		.ugc__linkLabel {
			margin-left: $base1;
			width: staticColumnSize(4, 6, $gutterSmall, $base1);

			@include media($smallLayout) {
				width: staticColumnSize(9, 12, $gutterMedium, $base1);
			}

			@include media($mediumLayout) {
				width: staticColumnSize(4, 6, $gutterMedium, $base1);
			}

			@include media($largeLayout) {
				width: staticColumnSize(4, 6, $gutterLarge, $base1);
			}
		}
	}


	q {
		@include font(titleExtraLight);
		font-style: italic;
		quotes: "\00BB" "\00AB";
	}


	blockquote,
	blockquote p {
		@include font(titleExtraLight);
		margin: 0;
		padding-left: 0;
		border-left: none;
		text-align: center;
		margin-top: $base3;
		margin-bottom: $base3;

		@include media($smallLayout) {
			margin-bottom: $base5;
			margin-top: $base5;
		}

		// > p:first-child::before {
		// 	content: open-quote;
		// }

		// > p:last-of-type::after {
		// 	content: close-quote;
		// }
	}


	cite {
		@include font(base);
		display: block;
		font-style: normal;
		text-align: right;


		&::before {
			content: '\2014';
			margin-right: 0.2em;
		}
	}

	blockquote cite {
		margin-top: $base;
	}

	q + cite {
		display: inline;
		margin-left: $base;
	}

	strong {
		font-family: $fontFamilyAlt;
		font-weight: $weightBold;
		font-style: normal;

		.block--fontSizeSmall & {
			font-weight: $weightSemiBold;
		}
	}


	dl {
		margin: 0;
	}


	dd {
		margin: 0;
	}


	dt {
		@include font(base);
		display: block;
		font-weight: bold;
	}

	p {
		@include font(base);

		.block--fontSizeSmall & {
			@include font(baseSmall);
		}

		.footer & {
			@include font(detailMedium);
		}

		.block--slideshow & {
			@include font(baseSmallAlternate);
		}

		.trackers & {
			@include font(detailMedium);
		}
	}


	h3 {
		@include font(baseBold);
		color: $colorAlternative;
		text-align: center;

		.footer & {
			@include font(detailMedium);
		}
	}

	h1,
	h2,
	h4,
	h5,
	h6 {
		@include font(baseSemiBold);

		.footer & {
			@include font(detailMedium);
		}
	}


	hr {
		background-color: $colorUgcSeparator;
		border-width: 0;
		border: 0;
		height: $hrSize;
		width: 100%;
		margin-left: 0;
		margin-right: 0;
		margin-bottom: $base2;
		margin-top: $base3;

		&:first-child {
			margin-top: 0;
		}
	}


	li {
		margin: 0;
	}

	ol > li {
		padding-left: 0;
	}


	ol {
		margin: 0 0 0 $base2;
	}


	ul {
		margin: 0 0 0 $base2;
	}

	ul > li {
		position: relative;

		&::before {
			content: '\2022';
			position: absolute;
			top: 0;
			transform-origin: 0 50%;
			left: -$base2;
		}
	}



	// h1,
	// h2,
	// h3,
	// h4,
	// h5,
	// h6,
	// p,
	// ol,
	// ul,
	// dl,
	// blockquote {
	// 	//max-width: $textMaxWidth;
	// }

	* + a > figure {
		margin-top: 1em;
	}


	h1:first-child:not(:only-child),
	h2:first-child:not(:only-child),
	h3:first-child:not(:only-child),
	h4:first-child:not(:only-child),
	h5:first-child:not(:only-child),
	h6:first-child:not(:only-child) {
		margin-bottom: $base3;

		@include media($smallLayout) {
			margin-bottom: $base4;
		}

		.block--fontSizeSmall & {
			margin-bottom: 0;
		}
	}


	// space before heading
	* + h1,
	* + h2,
	* + h3,
	* + h4,
	* + h5,
	* + h6,
	* + div {
		// margin-top: $base3;
		margin-top: $base2;
	}


	// space before all common elements in the content
	* + table,
	* + figure,
	* + p,
	* + dl,
	dd + dd,
	dt + dt {
		margin-top: $base2;

		.block--fontSizeSmall & {
			margin-top: $base1;
		}
	}

	// space before lists
	* + ol,
	* + ul {
		margin-top: $base * 2.5;

		@include media($smallLayout) {
			margin-top: $base * 3.5;
		}
	}


	// space between list items
	li + li {
		margin-top: $baseHalf;

		@include media($smallLayout) {
			margin-top: $base1;
		}
	}


	// special style between definition list items
	dd + dt {
		margin-top: $base2;
	}


	// // special margin around the blockquote
	// * + blockquote,
	// blockquote + *:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6) {
	// 	margin-top: $base2;
	// }

	// special margin around hr separator
	* + hr,
	hr + * {
		//margin-top: $base2;
	}
}
