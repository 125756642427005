.form {
	position: relative;


	&__buttons {
		// this removes the space between inline-blocks / inline-flex elements.
		// the proper font size is set in the children
		font-size: 0;
		margin-left: -$base2;
		margin-bottom: -$base2;

		* + & {
			margin-top: $base3;
		}

		.form__separator + & {
			margin-top: $base4;
		}
	}


	&__fieldError {
		@include font(detail);
		margin: $base 0 $base2 0;
		width: dynamicColumnSize(1, 1, $gutterMedium);
	}


	&__fields {
		@include dynamicColumns($hSpace: $gutterMedium, $vSpace: $base2, $hSpacePos: left, $vSpacePos: top);
		position: relative;

		.newsletterSignup & {
			align-items: flex-start;
			justify-content: center;

			@include media($mediumLayout) {
				justify-content: flex-start;
			}
		}
	}


	&__separator {
		background-color: currentColor;
		border: 0;
		height: $boxBorderSize;
		margin-bottom: 0;
		display: block;
		width: 100%;

		.form__fields & {
			width: dynamicColumnSize(9, 9, $gutterMedium);
		}

		* + & {
			margin-top: $base4;
		}
	}


	&__stateFieldWrapper {
		// @include ease(background-color, $hoverDuration);
		// background-color: $colorBgInput;
		// border-left: solid $inputBorderSize $colorInputBorder;
		// display: flex;
		// position: relative;

		// @include onHover() {
		// 	background-color: $hoverColorBgInput;
		// }
	}


	&__text {
		.newsletterSignup & {
			@include font(detail);
		}

		// &--newsletterIntro {
		// 	@include app-newsletterState;
		// 	@include font(form);
		// 	cursor: pointer;
		// 	flex-grow: 1;


		// 	.form.js-insertEmail &,
		// 	.form.js-response & {
		// 		display: none;
		// 	}

		// 	.newsletterSignup .form & {
		// 		@include font(form);
		// 	}
		// }

		// &--response {
		// 	@include app-newsletterState;
		// }

		.form__fields & {
			width: dynamicColumnSize(1, 1, $gutterMedium);
		}

		.form__buttons & {
			@include font(base);
			margin: $base3 0 $base3 $base2;

			&:first-child {
				margin-top: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		.form > & {
			margin-bottom: $base3;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.form > * + & {
			margin-top: $base3;
		}

		.form__separator + & {
			margin-top: $base4;
		}
	}
}
