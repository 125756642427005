@use "sass:math";

.newImageHighlight {
	&__category {
		@include font(newCategory);
		display: none;
		margin: {
			bottom: $vwS10;
			top: 0;
		}
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			display: block;
			margin-bottom: $vwM30;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin: {
				bottom: $vwL50;
				top: 0;
			}
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			margin-bottom: $base5;
		}

		&--mobileVersion {
			display: block;

			//>=768px
			@include media($mediumNewLayout) {
				display: none;
			}
		}
	}

	&__content {
		@include staticColumns();
		flex-direction: column;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			flex-direction: row;
			margin-left: 0;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-left: staticColumnPush(1, 12, $newGutterLarge);
			width: staticColumnSize(10, 12, $newGutterLarge);
		}
	}

	&__contentWrapper {
		padding-left: 0;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			padding-left: $vwM10;
			width: staticColumnSize(6, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-left: $vwL30;
			width: staticColumnSize(5, 10, $newGutterLarge);
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			padding-left: 0;
			width: staticColumnSize(4, 10, $newGutterLarge);
		}
	}

	&__description {
		@include font(newHeadingFifthV2);
		margin-bottom: $vwS05;

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-bottom: $vwL10;
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			margin-bottom: $base1;
		}

		> * {
			margin-bottom: $vwS10;

			//>=768px
			@include media($mediumNewLayout) {
				margin-bottom: $vwM15;
			}
			//>=1280px
			@include media($largeNewLayout) {
				margin-bottom: $vwL20;
			}
			//>=1440px
			@include media($xLarge2vNewLayout) {
				margin-bottom: $base2;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}

		a {
			@include new-alternative-link();
		}
	}


	&__image {
		height: auto;
		width: 100%;
	}

	&__imgWrapper {
		margin-bottom: $vwS10;
		overflow: hidden;
		position: relative;

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: 0;
			width: staticColumnSize(6, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(5, 10, $newGutterLarge);
		}

		.newImageHighlight--narrow & {
			//>=768px
			@include media($mediumNewLayout) {
				margin-left: staticColumnPush(1, 12, $newGutterSmall);
				width: staticColumnSize(5, 12, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				margin-left: 0;
				width: staticColumnSize(4, 10, $newGutterLarge);
			}
			//>=1440px
			@include media($xLarge2vNewLayout) {
				margin-left: staticColumnPush(1, 10, $newGutterLarge);
			}
		}
	}

	&__link {
		@include font(newHeadingFifthV2);
		@include new-alternative-link();
	}
}
