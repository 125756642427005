@use "sass:meta";

/**
	Take a map of properties to lists of values, one value for each stop specified in the stops list
	default is a special value without a media query wrapper
 */
@mixin responsiveProps($stops: (default, $mediumNewLayout, $largeNewLayout, $xLargeNewLayout), $args...) {
	@each $stop in $stops {
		$idx: index($stops, $stop);
		@if ($stop == default) {
			@each $property, $value in meta.keywords($args) {
				// default values
				#{$property}: nth($value, $idx);
			}
		} @else {
			@include media($stop) {
				// responsive variant values
				@each $property, $value in meta.keywords($args) {
					#{$property}: nth($value, $idx);
				}
			}
		}
	}
}
