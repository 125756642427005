.download {
	&,
	.ugc & {
		align-items: center;
		display: flex;
		text-decoration: none;
	}


	&__content {
		&,
		.ugc &,
		.ugc * + & {
			margin-top: 0;
		}
	}


	&__fileName {
		&,
		.ugc & {
			@include font(baseSmall);
			font-weight: normal;
			margin-top: 0;
		}
	}


	&__fileTitle {
		&,
		.ugc &,
		.ugc * + &,
		.ugc &:first-child:not(:only-child) {
			@include font(baseSmallSemiBold);
			margin-bottom: 0;
			text-decoration: underline;
			margin-top: 0;
		}
	}


	&__icon {
		margin-right: $base2;

		[data-type="svg"] {
			display: block;
		}
	}
}
