.textHighlight {
	&__category {
		@include font(newCategory);
		margin-top: 0;
		margin-bottom: $vwS20;
		padding-right: 0;
		text-align: left;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin: {
				bottom: 0;
				top: $base;
			}
			padding-right: $categoryMediumPadding;
			text-align: right;
			width: staticColumnSize(4, 10, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $mediumVerticalSpacing;
			padding-right: $vwL20;
			width: staticColumnSize(3, 10, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding-right: $base2;
		}
	}

	&__content {
		@include staticColumns();
		flex-direction: column;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			flex-direction: row;
			width: staticColumnSize(10, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(10, 12, $newGutterLarge);
		}
	}

	&__contentWrapper {
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(6, 10, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(7, 10, $newGutterLarge);
		}
	}
}
