.newsletterAnchor {
	align-items: center;
	border-radius: $base2;
	bottom: $headerSpacingSmall;
	display: flex;
	background-color: $colorHighlightLight;
	height: $newsletterAnchorSpacing;
	max-width: $newsletterAnchorSpacing;
	padding: 0 $base * 0.9;
	position: absolute;
	pointer-events: all;
	right: $containerPaddingXSmall;
	transform: scale(0);
	transition:
		background-color $hoverDuration,
		max-width $newsletterAnchorExpandDuration,
		transform $newsletterAnchorScaleDuration,
		padding $newsletterAnchorExpandDuration cubic-bezier(0, 0.88, 0.43, 1.13);
	overflow: hidden;

	@include media($smallLayout) {
		bottom: $headerSpacingMedium;
		right: $containerPaddingSmall;
	}

	@include media($mediumLayout) {
		right: $base * 2.4;
		bottom: $headerSpacingLarge;
	}

	@include media($largeLayout) {
		right: $base * 3.5;
	}

	&.js-hidden {
		display: none;
	}

	&.js-initialAnimation {
		transform: scale(1);

		@include onHover() {
			background-color: $colorHighlight;
			max-width: 50%;
			padding: 0 $base * 1.7;

			@include media($mediumLayout) {
				max-width: 25%;
			}
		}
	}

	&.js-expanded {
		background-color: $colorHighlight;
		max-width: 50%;
		padding: 0 $base * 1.7;

		@include media($mediumLayout) {
			max-width: 25%;
		}
	}


	&__icon {
		@include fadeOut();
		align-items: center;
		display: inline-flex;
		height: $newsletterAnchorIconSize;

		.newsletterAnchor.js-initialAnimation & {
			@include fadeIn($newsletterAnchorFadeDuration, $easeBase, $newsletterAnchorScaleDuration * 0.5);
		}
	}

	&__label {
		@include fadeOut($newsletterAnchorFadeDuration);
		@include font(highlightMedium);
		color: $colorInverted;
		margin-left: $base1;
		vertical-align: text-bottom;
		white-space: nowrap;

		.newsletterAnchor.js-expanded & {
			@include fadeIn();
		}

		@include onHover('.newsletterAnchor.js-initialAnimation', '&') {
			@include fadeIn();
		}
	}

	&__link {
		align-items: center;
		display: flex;
		flex-wrap: nowrap;
		text-decoration: none;
	}
}
