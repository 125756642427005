.newContentList {
	overflow: hidden;
	position: relative;
	width: 100%;
	z-index: 1;

	@include media($largeNewLayout) {
		@include staticColumns();
	}

	&__category {
		@include font(newCategory);
		color: var(--color);
		margin-bottom: $vwS20;
		padding: {
			bottom: 0;
			right: 0;
		}
		text-align: start;
		width: staticColumnSize(4, 6, $newGutterSmall);

		//>=428px
		@include media($smallNewLayout) {
			width: staticColumnSize(10, 12, $newGutterSmall);
		}

		//>=768
		@include media($mediumNewLayout) {
			margin-bottom: $base0;
			text-align: start;
			padding: {
				bottom: $vwM20;
				right: 0;
			}
			width: 100%;
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				bottom: 0;
				right: $vwL20;
			}
			text-align: right;
			width: staticColumnSize(3, 12, $newGutterLarge);
		}
	}

	&__itemsContainer {
		position: relative;
		width: 100%;

		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(9, 12, $newGutterLarge);
		}
	}

	&__items {
		display: flex;
		flex-wrap: wrap;

		//>=768px
		@include media($mediumNewLayout) {
			column-gap: $newGutterSmall;
			//margin-top: $vwM50;
			row-gap: $vwM50;
		}
		//>=1280px
		@include media($largeNewLayout) {
			column-gap: $newGutterLarge;
			margin-top: 0;
			row-gap: $vwL100;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			row-gap: $base10;
		}
	}

	&__separator {
		width: 100%;
		height: 1px;
		background-color: var(--border-color);
		margin: {
			top: $vwS20;
			bottom: $vwS20;
		}
		//>=768px
		@include media($mediumNewLayout) {
			display: none;
		}

		&:last-child {
			margin-bottom: $base0;
		}
	}
}
