.textSlider {
	padding-top: $vwS30;
	position: relative;
	width: 100%;
	z-index: 1;

	//>=768px
	@include media($mediumNewLayout) {
		padding-top: $vwM20;
	}

	//>=1680px
	@include media($xLargeNewLayout) {
		padding-top: $base2;
	}

	&__arrowButton {
		cursor: grab;
		display: none;
		left: 0;
		position: absolute;
		top: 0;
		transform: translate(-50%, -50%);
		z-index: 1000;
	}

	&__arrowButtonIcon {
		[data-type="svg"] > svg {
			fill: none;
		}

		.textSlider__arrowButton.js-rotate & {
			transform: scaleX(-1);
		}
	}

	&__category {
		@include font(newCategory);
		left: 0;
		position: absolute;
		text-align: left;
		top: 0;
		width: staticColumnSize(4, 6, $newGutterSmall);

		//>=768px
		@include media($mediumNewLayout) {
			margin-bottom: 0;
			padding-right: $categoryMediumPadding;
			text-align: right;
			width: staticColumnSize(4, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-right: $vwL20;
			width: staticColumnSize(3, 12, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			// top: $base338; //33.8rem;
			margin-bottom: $base2;
			padding-right: $base2;
		}
	}

	&__categoryWrapper {
		@include app-newcontainer();
		position: absolute;
		top: 0;

		//>=768px
		@include media($mediumNewLayout) {
			position: relative;
		}
	}

	&__categoryWrapperInner {
		@include staticColumns();
		position: relative;
	}

	&__items {
		@include staticColumns();
		justify-content: left;
		width: 100%;

		&::after {
			content: "";
			display: inline-block;
			height: $base10;
			min-width: $newContainerPaddingSmall - $newGutterSmall;
			position: relative;

			@include media($smallNewLayout) {
				min-width: $newContainerPaddingMedium - $newGutterSmall;
			}

			@include media($mediumNewLayout) {
				min-width: $newContainerPaddingLarge - $newGutterSmall;
			}

			@include media($largeNewLayout) {
				min-width: $newContainerPaddingLarge - $newGutterLarge;
			}

			@include media($xLargeNewLayout) {
				min-width: calc(
					(100vw - #{$newContainerMaxWidth} + #{$newGutterLarge}) * 0.5
				);
			}
		}
	}

	&__itemsContainer {
		background-color: $colorBgBase;
		position: relative;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin-left: staticColumnPush(4, 12, $newGutterLarge);
			width: staticColumnSize(8, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-left: staticColumnPush(3, 12, $newGutterLarge);
			width: staticColumnSize(9, 12, $newGutterLarge);
		}
	}

	&__progress {
		@include staticColumns();
	}

	&__progressBar {
		background: $colorBase;
		height: pxToRem(1);
		margin-left: staticColumnPush(4, 6, $newGutterSmall);
		position: relative;
		width: staticColumnSize(2, 6, $newGutterSmall);

		//>=768px
		@include media($mediumNewLayout) {
			margin-left: staticColumnPush(10, 12, $newGutterSmall);
			width: staticColumnSize(2, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-left: staticColumnPush(10, 12, $newGutterLarge);
			width: staticColumnSize(2, 12, $newGutterLarge);
		}
	}

	&__progressInner {
		background-color: $colorBase;
		display: block;
		height: $newProgressInnerHeight;
		position: relative;
		top: -$newProgressBarHeight;
	}

	&__wrapper {
		@include app-newcontainer();
		padding-top: 0;
	}

	&__wrapperInner {
		@include staticColumns();
		flex-direction: column;
		position: relative;

		//>=768px
		@include media($mediumNewLayout) {
			flex-direction: row;
		}
	}

	&__wrapperProgress {
		@include app-newcontainer();
		background-color: inherit;
		height: auto;
		left: 0;
		position: absolute;
		right: 0;
		top: $vwS05;

		//>=768px
		@include media($mediumNewLayout) {
			top: 0;
		}
	}

	&__wrapperScroll {
		cursor: grab;
		-ms-overflow-style: none; // for IE
		overflow-x: scroll;
		scrollbar-width: none; // for Firefox

		// for Opera, Chrome, Edge, and Safari, Firefox
		&::-webkit-scrollbar {
			display: none;
		}
	}
}
