.lettersAnimation {
	@mixin delayedTransition($delay:0s) {
		@for $i from 0 through 50 {
			&:nth-child(#{$i}) {
				transition:
					color $durationFast 0.015s * $i linear,
				//	visibility $durationFast $delay linear,
				//	opacity $durationFast $delay linear;
			}
		}
	}


	&__letter {
		@include delayedTransition();

		.lettersAnimation:hover & {
			color: $colorHighlight;
		}

		.mainMenu .menu__items--nested &,
		.mainMenu .menu__items--submenu & {
			//visibility: hidden;
		//	opacity: 0;
		//	@include delayedTransition(0s);
		}

		.mainMenu.js-active .menu__items--nested &,
		.mainMenu.js-active .menu__items--submenu & {
		//	@include delayedTransition(0.4s);
		//	visibility: inherit;
		//	opacity: 1;
		}
	}
}
