.videoPlayer {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;

	.video--newOverlay & {
		position: relative;
		width: 100%;
		display: block;
		height: auto;
	}


	&--bg {
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
		z-index: -1;
	}


	&__embed {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;

		.contentPreview & {
			height: 100%;
			min-height: 100%;
			min-width: 100%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		.video--newOverlay & {
			position: relative;
			width: 100%;
			display: block;
			height: auto;
		}

		.video--overlay & {
			object-fit: cover;
		}

		> iframe,
		> video {
			border: 0;
			height: 100%;
			width: 100%;
		}

		.contentPreview & {
			object-fit: cover;
		}

		// .newMainTeaser & {
		// 	min-height: 100%;
		// 	height: auto;
		// }
	}

	> video.videoPlayer__embed:not(.js-loading) {
		opacity: 0;
	}


}
