.simpleTeaser {

	&__category {
		@include font(newCategory);
		margin: {
			bottom: $vwS05;
			top: 0;
		}
		padding-left: $categoryMediumPadding;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin: {
				bottom: 0;
				top: $base2;
			}
			padding-left: 0;
			padding-right: $categoryMediumPadding;
			text-align: right;
			width: staticColumnSize(4, 11, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-right: $vwL20;
			margin-top: $largeVerticalSpacing;
			width: staticColumnSize(3, 9, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			padding: {
				left: 0;
				right: $base2;
			}
			width: staticColumnSize(3, 8, $newGutterLarge);
		}
	}

	&__content {
		@include staticColumns();
		flex-direction: column;
		margin-left: 0;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			flex-direction: row;
			width: staticColumnSize(11, 12, $newGutterSmall);
		}
		//>=1024px
		@include media($mediumLayout) {
			width: staticColumnSize(11, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(9, 12, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(8, 12, $newGutterLarge);
		}
	}

	&__link {
		@include font(newHeadingSecondV3);
		@include new-large-title-link();
		color: var(--highlight-color);

		&:hover {
			color: var(--background-color);
		}
	}

	&__textWrapper {
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(7, 11, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(6, 9, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(5, 8, $newGutterLarge);
		}
	}

	&__title {
		@include font(newHeadingSecondV3);
	}
}
