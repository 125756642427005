.newListBlockProgram {
	position: relative;
	align-items: baseline;

	//>=768px
	@include media($mediumNewLayout) {
		@include staticColumns();
		width: staticColumnSize(11, 12, $newGutterSmall);
	}
	//>=1280px
	@include media($largeNewLayout) {
		width: staticColumnSize(10, 12, $newGutterLarge);
	}
	//>=1680px
	@include media($xLargeNewLayout) {
		width: staticColumnSize(9, 12, $newGutterLarge);
	}

	&__arrowBtn {
		display: flex;
		flex-direction: flex-end;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);

		[data-type="svg"] > svg {
			fill: none;
		}

		& > span {
			margin-left: auto;
		}
	}

	&__header {
		@include font(newCategory);
		color: $colorBgBase;
		text-align: start;
		padding: {
			bottom: $vwS20;
		}

		//>=768px
		@include media($mediumNewLayout) {
			padding: {
				right: $categoryMediumPadding;
				bottom: 0;
			}
			text-align: right;
		}

		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				right: $vwL20;
			}
		}

		//>=1440PX
		@include media($xLarge2vNewLayout) {
			padding-right: $base2;
		}

		.block--lightColorVersion &,
		.block--veryLightColorVersion & {
			color: $colorBase;
		}
	}

	&__headerWrapper {
		width: 100%;
		display: flex;
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(4, 11, $newGutterSmall);
			height: $base4 + 0.912;
			justify-content: flex-end;
		}

		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(3, 10, $newGutterLarge);
			height: $base8 + 0.455;
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			height: $base9 + 0.5;
			width: staticColumnSize(3, 9, $newGutterLarge);
		}
	}

	&__bodyWrapper {
		width: 100%;
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(7, 11, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(7, 10, $newGutterLarge);
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			width: staticColumnSize(6, 9, $newGutterLarge);
		}
	}
	//
	&__wrapperLink {
		display: grid;
		text-decoration: none;
		position: relative;
		width: 100%;
		height: 100%;
		padding: {
			top: $vwS;
			bottom: $vwS15;
			right: pxToRem(34);
		}
		grid-template-columns: 1fr;
		transition: 0.3s all;

		//>=768px
		@include media($mediumNewLayout) {
			grid-template-columns: 1fr;
			padding: {
				top: $vwM;
				bottom: $vwM05;
			}
		}

		//>=1280px
		@include media($largeNewLayout) {
			grid-template-columns: 1.54fr 1.8fr 34px;
			padding: {
				top: $vwL15;
				bottom: $vwL20;
				right: $base0;
			}
		}

		//>=1680px
		@include media($xLargeNewLayout) {
			grid-template-columns: 1fr 1.8fr 34px;
			padding: {
				top: $base1 + 0.5;
				bottom: $base2;
			}
		}

		&--hasHover {
			&:hover {
				.newListBlockProgram__textLink {
					color: $colorHighlight;
					transition: 0.3s all;

					.block--vividColorVersion & {
						color: $colorBase;
					}
				}
			}
		}

		&--emptyArrow {
			padding-right: $base0;
			//>=1280px
			@include media($largeNewLayout) {
				grid-template-columns: 1.52fr 1.92fr;
			}
			//>=1680px
			@include media($xLargeNewLayout) {
				grid-template-columns: 1fr 1.92fr;
			}
		}
	}

	&__textLink {
		display: block;
		@include font(newBase);
		color: $colorBgBase;
		transition: 0.3s all;

		.block--lightColorVersion &,
		.block--veryLightColorVersion & {
			color: $colorBase;
		}
	}

	&__titleLink {
		display: block;
		@include font(newBase);
		color: $colorBgAlternative;

		.block--vividColorVersion & {
			color: $colorBgBase;
			opacity: 0.8;
		}
	}

	&__structurLink {
		border-top: 1px solid $colorBgAlternative;
		position: relative;

		.block--vividColorVersion & {
			border-top: 1px solid $colorBgBase;
		}

		&:last-child {
			border-bottom: 1px solid $colorBgAlternative;

			.block--vividColorVersion & {
				border-bottom: 1px solid $colorBgBase;
			}
		}
	}

	&__orangeText {
		color: $colorHighlight;

		.block--veryLightColorVersion &,
		.block--lightColorVersion & {
			color: $colorHighlight;
		}

		.block--vividColorVersion & {
			color: $colorBase;
		}
	}
}
