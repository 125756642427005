.newLinksList {
	margin-top: $vwS05;

	&--textBlock {
		@include font(newSocialMediaLink);
		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM05;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base1;
		}
	}

	&--editorialHero {
		@include font(newHeadingFifthV2);
		width: 100%;
		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding-right: staticColumnSize(1, 6, $newGutterLarge);
			margin-top: $vwL20;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base2;
		}

		.newEditorialHero__textWrapper--noneMedia & {
			//>=768px
			@include media($mediumNewLayout) {
				padding-right: 0;
				width: staticColumnSize(5, 9, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				padding-right: 0;
				width: staticColumnSize(5, 9, $newGutterLarge);
			}
		}
	}

	&__link {
		@include ease(color, $hoverDuration);
		color: $colorHighlight;
		cursor: pointer;
		//display: inline-block;
		// margin: {
		// 	left: $baseHalf;
		// 	right: $baseHalf;
		// }
		position: relative;
		text-decoration: none;
		width: auto;
		border-bottom: {
			color: transparent;
			style: solid;
			width: 1px;
		}

		@include onHover() {
			color: $colorHighlight;
			border-bottom-color: $colorHighlight;
		}

		// &::after {
		// 	content: '|';
		// 	position: absolute;
		// 	right: -$base1;
		// }

		// .newEditorialHero__linkWrapper + .newLinksList__linkWrapper &::before {
		// 	content: '|';
		// 	color: $colorBase;
		// 	left: -$base1;
		// 	position: absolute;
		// }

		// .newLinksList__linkWrapper:last-child & {
		// 	margin-right: 0;
		// }

		// .newLinksList__linkWrapper:last-child &::after {
		// 	content: '';
		// }

		.block--vividColorVersion & {
			color: $colorBase;
			@include onHover() {
				color: $colorBase;
				border-bottom-color: colorBase;
			}
		}

		.block--darkColorVersion & {
			@include onHover() {
				border-bottom-color: $colorBgBase;
			}
		}
	}

	&__linkWrapper {
		position: relative;
		display: inline;
		color: $colorHighlight;

		.block--vividColorVersion & {
			color: $colorBase;
		}

		&::after {
			content: "|";
		}

		&:last-child {
			&::after {
				content: inherit;
			}
		}

		&--date {
			color: $colorBase;
		}
	}
}
