.newSubnavigation {
	position: relative;

	//>=768px
	@include media($mediumNewLayout) {
		width: staticColumnSize(11, 12, $newGutterLarge);
		@include staticColumns();
	}
	//>=1280px
	@include media($largeNewLayout) {
		width: staticColumnSize(10, 12, $newGutterLarge);
	}

	&__arrowRight {
		position: absolute;
		top: $base * 0.2;
		z-index: 10;
		right: -$base;
		padding-left: $base2;
		padding-right: $base;

		stroke: $colorHighlight;
		filter: drop-shadow(0 0 5px rgb(0 0 0 / 1));
		//background-color: rgba(0, 0, 0, 0.65);
		background: linear-gradient(
			90deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 1) 100%
		);

		svg {
			filter: drop-shadow(0 0 2px rgb(0 0 0 / 1));
		}

		.block--darkColorVersion & {
			filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 100));
		}

		[data-type="svg"] > svg {
			fill: none;

			.block--vividColorVersion & {
				color: $colorBase;
			}
		}

		@include media($mediumNewLayout) {
			display: none;
		}
	}

	&__menuLinks {
		display: flex;
		position: relative;
		column-gap: $base2;

		@include media($mediumNewLayout) {
			width: staticColumnSize(7, 11, $newGutterSmall);
			margin-left: staticColumnPush(4, 11, $newGutterSmall);
			flex-wrap: wrap;
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(7, 10, $newGutterLarge);
			margin-left: staticColumnPush(3, 10, $newGutterLarge);
		}
	}

	&__menuLink {
		display: inline-block;
		white-space: nowrap;

		@include media($mediumNewLayout) {
			white-space: wrap;
		}
	}

	&__linkText {
		@include font(newProgramLink);
		color: $colorHighlight;
		text-transform: uppercase;
		display: inline-block;
		text-decoration: none;

		&:hover {
			border-bottom: 1px $colorHighlight solid;
		}

		.block--vividColorVersion & {
			color: $colorBgBase;

			&:hover {
				border-bottom: 1px $colorBase solid;
			}
		}
	}
}
