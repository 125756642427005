.text {
	@include font(base);

	.block--teaser.block--vAlignJustified & {
		flex-grow: 1;
	}

	// an example, eventually would need to be applied also to title, subtitle and category
	.block--extraLeftMargin & {
		@include media($columnsBreakpoint) {
			padding-left: $base4;
		}
	}


	&--summary {
		@include font(titleExtraLight);
	}

	&--summaryHighlight {
		@include font(titleMedium);
		font-style: normal;
	}

	.block--content-teaser & {
		@include font(baseSmall);
	}

	.block--content-teaser &--detail {
		@include font(headerServiceMenu);
		font-style: normal;
		font-weight: $weightRegular;
		text-transform: uppercase;
		margin-right: $base1;
	}
}
