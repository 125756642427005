.newFormField {
	width: 100%;

	&--text {
		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(3, 8, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(3, 8, $newGutterLarge);
		}

		&:first-child {
			//>=768px
			@include media($mediumNewLayout) {
				width: staticColumnSize(2, 8, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				width: staticColumnSize(3, 8, $newGutterLarge);
			}
		}

		&:nth-child(2) {
			margin-top: $vwS10;
			//>=768px
			@include media($mediumNewLayout) {
				margin-top: 0;
			}
		}
	}

	&--newsletterAcceptDataPolicy {
		display: none;
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			width: staticColumnSize(5, 8, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			width: staticColumnSize(6, 8, $newGutterLarge);
		}

		.newForm.js-insertedName & {
			display: block;
		}
	}

	&__input {
		@include ease(background-color, $hoverDuration);
		@include font(newBaseSmall);

		&,
		&:active,
		&:focus {
			background-color: $newColorBgInput;
			color: $colorInput;
		}

		&:focus {
			outline-color: $colorBgBase;
			outline-style: solid;
			outline-width: $inputBorderSize;

			.newsletterSignup & {
				outline: none;
			}
		}

		&--checkbox {
			@include visuallyHidden();
		}

		&--newsletterEmail {
			border: solid $inputBorderSize $newColorBgInput;
			display: block;
			padding: $newFormElemMediumSpacing $newFormElemLargeSpacing $newFormElemSmallSpacing;
			width: 100%;
		}
	}

	&__inputWrapper {
		margin-top: $vwS05;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM05;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL05;
		}
		//>=1440px
		@include media($xLarge2vNewLayout) {
			margin-top: $baseHalf;
		}

		.newFormField--checkbox & {
			margin-top: $vwS10;

			//>=768px
			@include media($mediumNewLayout) {
				margin-top: $vwM20;
			}
			//>=1280px
			@include media($largeNewLayout) {
				margin-top: $vwL20;
			}
			//>=1440px
			@include media($xLarge2vNewLayout) {
				margin-top: $base2;
			}
		}
	}

	&__label {
		&--checkbox {
			display: block;
			padding-left: $base3;
			position: relative;

			//>=1440px
			@include media($xLarge2vNewLayout) {
				padding-left: $newFormCheckHorizontalSpacing;
			}
		}
	}

	&__labelCheck {
		border: $inputBorderSize solid $colorBgBase;
		height: $newCheckboxSize;
		left: 0;
		position: absolute;
		top: 0;
		width: $newCheckboxSize;

		&::after {
			@include ease(opacity, $duration2);
			background-color: $colorBgBase;
			content: '';
			display: block;
			height: $base1 + 0.2;
			left: $newCheckboxPadding;
			opacity: 0;
			position: relative;
			top: $newCheckboxPadding;
			width: $base1 + 0.2;

			input[type="checkbox"]:checked + .newFormField__label & {
				opacity: 1;
			}
		}
	}

	&__labelLink {
		@include new-newsletter-main-link();
	}

	input::placeholder {
		color: $colorBase;
	}
}
