// the regular version is directly requested in the <head> in order to prefetch the file
@font-face {
	font-family: 'Brando';
	src:
		url('../fonts/brando-regular-italic.woff2') format('woff2'),
		url('../fonts/brando-regular-italic.woff') format('woff');
	font-display: swap;
	font-style: italic;
	font-weight: normal;
}

@font-face {
	font-family: 'Brando';
	src:
		url('../fonts/brando-light-italic.woff2') format('woff2'),
		url('../fonts/brando-light-italic.woff') format('woff');
	font-display: swap;
	font-style: italic;
	font-weight: 300;
}

@font-face {
	font-family: 'Brando';
	src:
		url('../fonts/brando-extralight-italic.woff2') format('woff2'),
		url('../fonts/brando-extralight-italic.woff') format('woff');
	font-display: swap;
	font-style: italic;
	font-weight: 200;
}

@font-face {
	font-family: 'Brando';
	src:
		url('../fonts/brando-bold.woff2') format('woff2'),
		url('../fonts/brando-bold.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: bold;
}

@font-face {
	font-family: 'WorkSans';
	src:
		local(''),
		url('../fonts/work-sans-v7-latin-regular-mac.woff2') format('woff2'),
		url('../fonts/work-sans-v7-latin-regular.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: normal;
}

@font-face {
	font-family: 'WorkSans';
	src:
		local(''),
		url('../fonts/work-sans-v7-latin-300-mac.woff2') format('woff2'),
		url('../fonts/work-sans-v7-latin-300.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: 300;
}

@font-face {
	font-family: 'WorkSans';
	src:
		local(''),
		url('../fonts/work-sans-v7-latin-500-mac.woff2') format('woff2'),
		url('../fonts/work-sans-v7-latin-500.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'WorkSans';
	src:
		local(''),
		url('../fonts/work-sans-v7-latin-600-mac.woff2') format('woff2'),
		url('../fonts/work-sans-v7-latin-600.woff') format('woff');
	font-display: swap;
	font-style: normal;
	font-weight: 600;
}
