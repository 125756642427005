.newWideHeadline {
	&__content {
		@include staticColumns();
		width: 100%;

		//>=768px
		@include media($mediumNewLayout) {
			margin-left: 0;
			width: staticColumnSize(11, 12, $newGutterSmall);
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-left: staticColumnPush(1, 12, $newGutterLarge);
			width: staticColumnSize(10, 12, $newGutterLarge);
		}
	}

	&__contentWrapper {
		@include font(newHeadingOneV2);
		width: 100%;

		.newWideHeadline--narrow & {

			//>=768px
			@include media($mediumNewLayout) {
				width: staticColumnSize(8, 11, $newGutterSmall);
			}
			//>=1280px
			@include media($largeNewLayout) {
				width: staticColumnSize(8, 10, $newGutterLarge);
			}
		}
	}
}
