.newMenuToggler {
	&__icon {
		position: relative;
	}

	&__text {
		@include ease(max-width margin-right opacity, $durationFast, $easeBase);
		display: block;
		margin-right: $base1 + $baseHalf;
		max-width: $base7; //a bit more than 'menu' label width
		opacity: 1;
		overflow: hidden;
		position: relative;

		.body.js-scrolledDown & {
			margin-right: 0;
			max-width: 0;
			opacity: 0;
		}
	}

	&__toggler {
		@include font(headerLinks);
		@include new-toggler-link();
		align-items: center;
		cursor: pointer;
		display: flex;
		height: $headerNavSmallHeight;
		position: relative;
		padding: {
			left: $vwS10;
			right: $vwS10;
		}

		@include media($mediumNewLayout) {
			height: $headerNavMediumHeight;
			padding: {
				left: $vwM20;
				right: $vwM20;
			}
		}

		@include media($largeNewLayout) {
			height: $headerNavLargeHeight;
			padding: {
				left: $vwL20;
				right: $vwL20;
			}
		}

		@include media($xLargeNewLayout) {
			height: $headerNavXLargeHeight;
			padding: {
				left: $base2;
				right: $base2;
			}
		}
	}
}
