
.eventListItem {

	$defaultVertiSpacing: (5px, 5px, 10px, 10px);
	$closedHover: '#{&}:not(.js-toggled):hover';

	cursor: pointer;
	position: relative;
	@include font(newBaseSecond);

	@include media($mediumNewLayout) {
		display: grid;
		grid-template-columns: repeat(12, 1fr);
		column-gap: 10px;
	}
	@include media($largeNewLayout) {
		column-gap: 20px;
	}
	@include responsiveProps(
		// default, 1024, 1280, 1680
		$padding-top: (10px, 20px, 20px, 20px), //$padding-bottom: (15px, 20px, 20px, 20px),
	);

	// item separators, on mobile just borders
	border-top: 1px solid var(--border-color);

	&:last-child {
		border-bottom: 1px solid var(--border-color);
	}

	// on bigger screens use pseudo-elements to use the grid
	@include media($mediumNewLayout) {
		border: none;
		&:last-child {
			border: none;
		}
		&:before, &:last-child:after {
			content: '';
			grid-column: 4/12;
		}
		&:before {
			border-top: 1px solid var(--border-color);
			@include responsiveProps(
				$padding-top: (0, 20px, 20px, 20px),
			);
		}
		// special case
		&:last-child:after {
			@include responsiveProps(
				$padding-bottom: (0, 20px, 20px, 20px),
			);
			border-bottom: 1px solid var(--border-color);
		}
	}

	&__date {
		grid-column: 1/4;
		margin-bottom: 5px;
		@include font(newCategory);
		@include media($mediumNewLayout) {
			text-align: right;
			margin-right: 10px;
		}
	}

	&__dateCore {
		time {
			font-variant-numeric: tabular-nums lining-nums;
			white-space: nowrap;
			@include media($mediumNewLayout) {
				display: block;
			}
		}

		time + time {
			&:before {
				content: '–';
				color: var(--highlight-color);
				@include media($mediumNewLayout) {
					content: unset;
				}
			}
		}
	}

	&__main {
		grid-column: 4/8;
		position: relative;
		overflow: hidden;

		a {
			color: var(--highlight-color);
			text-decoration: none;

			&:hover {
				text-decoration: underline;
				text-decoration-color: var(--border-color);
			}

			@include media($largeNewLayout) {
				text-decoration: underline;
			}
		}
	}

	&__supplementary {
		display: none;
		@include media($largeNewLayout) {
			display: block;
			grid-column: 9/12;
		}
	}

	&__toggler {
		position: absolute;
		user-select: none;
		top: 13px;
		right: 0;
		grid-column: 11/12;
		@include media($mediumNewLayout) {
			top: 50px;
		}
	}

	&__additionalWrapper {
		transition: height 0.2s;
		position: relative;
	}

	&__additional {
		transition: opacity 0.2s;

		&.js-collapsed {
			opacity: 0;
			position: absolute;
		}

		@include responsiveProps(
			$padding-top: $defaultVertiSpacing,
		);
	}

	&__category {
		@include font(newCategory);
		@include responsiveProps(
			$margin-bottom: $defaultVertiSpacing,
		);
		@include ease(color, $durationBase, $easeBase);
		#{$closedHover} & {
			color: var(--highlight-color);
		}
	}

	&__title {
		display: block;
		@include font(newTitleSlider);
		cursor: pointer;
		@include ease(color, $durationBase, $easeBase);
		#{$closedHover} & {
			color: var(--highlight-color);
		}
	}

	&__copy {
		@include responsiveProps(
			$margin-bottom: $defaultVertiSpacing,
		);
	}

	&__readMore {
		@include responsiveProps(
			$margin-bottom: (10px, 5px, 10px, 10px),
		);
	}

	&__timeAndPlace {
		> span + span {
			&:before {
				content: ' | ';
				color: var(--highlight-color);
			}
		}
		.eventListItem__main & {
			@include responsiveProps(
				$padding-top: $defaultVertiSpacing,
				$margin-bottom: (10px, 5px, 10px, 10px)
			);
			@include media($largeNewLayout) {
				display: none;
			}
		}
		@include ease(color, $durationBase, $easeBase);
		#{$closedHover} & {
			color: var(--highlight-color);
		}
	}

	&__image {
		@include responsiveProps(
			$margin-top: (10px, 10px, 10px, 30px),
			$margin-bottom: (0, 0, 30px, 30px)
		);
	}


}
