@use "sass:math";

.personPreview {
	width: staticColumnSize(5, 6, $newGutterSmall);
	margin-right: $newGutterSmall;

	.js-imgNotScale {
		@include ease(transform, $duration3, $easeOutQuad);
		transform: scale(1);
	}

	.js-imgScale {
		@include ease(transform, $duration3, $easeOutQuad);
		transform: scale($scaleImg);
	}

	//>=768px
	@include media($mediumNewLayout) {
		width: staticColumnSize(4, 8, $newGutterSmall);
	}
	//>=1280px
	@include media($largeNewLayout) {
		margin-right: $newGutterLarge;
		width: staticColumnSize(2, 9, $newGutterLarge);
	}


	&__academy {
		@include font(newBaseSmall);
		margin-top: 0;

		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL05;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $xSmallVerticalSpacing;
		}
	}


	&__linkLine {
		.personPreview__linksWrapper &:last-child {
			display: none;
		}
	}


	&__linkText {
		@include new-alternative-link();
		display: inline;
		padding: {
			left: $baseHalf;
			right: $baseHalf;
		}
		position: relative;

		.personPreview__linksWrapper &:first-child {
			padding-left: 0;
		}
	}


	&__linksWrapper {
		@include font(newBaseSmall);
		color: $colorHighlight;
		margin-top: $vwS10;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL15;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $smallMarginTop;
		}
	}


	&__contentWrapper {
		margin-top: $vwS10;

		@include media($mediumNewLayout) {
			margin-top: $vwM15;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL20;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $base2;
		}
	}


	&__image {
		width: 100%;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		@include objectFit(cover);
		display: block;
	}


	&__imageWrapper {
		@include aspectRatio(math.div(5, 7));
		overflow: hidden;
		position: relative;
	}


	&__img {
		@include ease(transform, $duration3, $easeOutQuad);
		@include objectFit(cover);
		min-height: 100%;
		width: 100%;

		@include onHover('.newMainTeaser__mediaLink', ' &') {
			transform: scale($scaleImg);
		}
	}


	&__imgLink {
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}


	&__link {
		@include font(newBaseSmall);
		@include new-alternative-link();
		display: block;
		margin-top: $vwS10;

		//>=768px
		@include media($mediumNewLayout) {
			margin-top: $vwM10;
		}
		//>=1280px
		@include media($largeNewLayout) {
			margin-top: $vwL15;
		}
		//>=1680px
		@include media($xLargeNewLayout) {
			margin-top: $smallMarginTop;
		}
	}


	&__name {
		@include font(newHeadingFifth);
	}


	&__nameLink {
		@include new-title-link();
	}
}
