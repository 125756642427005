
@keyframes image {
	from {
		transform: translateY(-50%) rotate(0deg);

	}

	to {
		transform: translateY(-50%) rotate(360deg);
	}
}
