
@mixin app-toggleContainer($apply: true, $isNarrow: false, $isWide: false) {
	padding-left: if($apply, $containerPaddingXSmall, 0);
	padding-right: if($apply, $containerPaddingXSmall, 0);
	margin-left: auto;
	margin-right: auto;
	max-width: if($apply, if($isNarrow, $containerMaxWidthNarrow, if($isWide, $containerMaxWidthWide, $containerMaxWidth)), none);
	// max-width: if($apply, if($isWide, $containerMaxWidthWide, $containerMaxWidth), none);
	width: 100%;

	@each $breakpoint, $containerPadding in $containerPaddings {
		@include media($breakpoint) {
			padding-left: if($apply, $containerPadding, 0);
			padding-right: if($apply, $containerPadding, 0);
		}
	}
}
