.newCategory {
	@include font(newCategory);
	color: $colorBgBase;

	.block--veryLightColorVersion &,
	.block--lightColorVersion & {
		color: $colorBase;
	}


	&--leftPosition {
		text-align: start;
		padding: {
			bottom: $vwS20;
		}
		//>=768px
		@include media($mediumNewLayout) {
			padding: {
				right: $categoryMediumPadding;
				bottom: 0;
			}
			text-align: right;
		}
		//>=1280px
		@include media($largeNewLayout) {
			padding: {
				right: $vwL20;
			}
		}
		//>=1440PX
		@include media($xLargeNewLayout) {
			padding-right: $base2;
		}
	}
}
